// SPECIAL - Spezielles, Bug-Fixe, temporäre Sachen
// ---------------------------

// A) Bugfixe
// ----------

// Ab medium auf "fixed", weil das Sticky-Skript erst nach ein paar Pixeln anfängt zu arbeiten. So kommt es zu keinem Sprung
@include breakpoint(medium) {
  .topNav {
    position: fixed;
  }
  header {
    height: $topNavHeight;
  }
}

// Der Edge-Browser kriegt die SVG-Hintergründe nicht hin (kleines Dreieck nach unten). Wenn man Anführungszeichen austauscht, klappt es
// https://github.com/zurb/foundation-sites/issues/8545
select {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
}

// Reveal (modal-Bugfixe), für iOs
// das "unter dem Overlay"-Scrollen ist noch nicht behoben https://github.com/zurb/foundation-sites/issues/8628
.reveal.full {
  // iOS hatte viel Luft nach oben
  top: 0 !important;
}

// B) Spezielles
// -------------

.fairplay-badge {
  position: absolute;
  top: 65%;
  right: 15%;
  width: 160px;
  z-index: 1;
  @include breakpoint(small only) {
    display: none;
  }

  a {
    text-align: center;
    color: $white;
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
    font-family: $header-font-family;
    font-size: 1.25rem;
    font-weight: 400;
    text-transform: uppercase;

    &:hover {
      color: darken($white, 5%);
    }

    img {
      width: 160px;
    }
  }
}

// Photoswipe - Galerie
.pswp {
  z-index: 10 !important;
}
.gallery-small,
.gallery-large {
  width: 100%;
  float: left;
}
.gallery-small img,
.gallery-large img {
  width: 100%;
  height: auto;
}
.gallery-small figure,
.gallery-large figure {
  display: block;
  float: left;
  margin: 0 5px 5px 0;
  // Extrakleiner Breakpoint manuell festgelegt
  @media screen and (max-width: 360px) {
    width: calc(50% - 5px);
  }
  @include breakpoint(small) {
    width: calc(33% - 5px);
  }
  @include breakpoint(medium) {
    width: calc(20% - 5px);
  }
  @include breakpoint(large) {
    width: calc(16.666667% - 5px);
  }
  @include breakpoint(xlarge) {
    width: calc(14.285714% - 5px);
  }
}
.gallery-small figcaption,
.gallery-large figcaption {
  display: none;
}
@include breakpoint(medium) {
  .pswp__caption__center {
    font-size: 1rem !important;
    max-width: 700px !important;
  }
}
.pswp--touch .pswp__share-tooltip a {
  padding: 12px !important;
}

// Für die Map
.scrolloff {
  pointer-events: none;
}

// Sitemap / http://codepen.io/webcre8/pen/VYmXxd
.sitemap {
  max-width: 500px;
  margin: 0 auto;

  ul {
    list-style: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  ul li {
    line-height: 1.5rem;
    vertical-align: top;
    position: relative;
    a {
      text-decoration: none;
      display: inline-block;
    }
  }

  .lbereich {
    margin-top: 1.5rem;
    > ul {
      margin-top: 1rem;
    }
  }

  ul ul {
    margin-left: 1.5rem;
    margin-bottom: 0.75rem;
  }
  // Verbindende Linie:
  > ul > li > ul > li {
    border-left: 1px solid $medium-gray;
    &:last-child {
      border-left: 1px solid transparent;
    }
  }

  .lseite {
    position: relative;
    a {
      margin-left: 3.75rem;
    }
  }
  .lseite:before {
    content: "";
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
    border-left: 1px $medium-gray solid;
    border-bottom: 1px $medium-gray solid;
    position: absolute;
    top: -0.75rem;
    left: -1px;
  }
}

// whatsapp-reminder auf Anfrageformular
.whatsapp-reminder {
  width: 100%;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  background: #e60073;
  color: white;
  font-size: 13px;
  z-index: 1;
  font-weight: bold;
  text-align: center;

  @include breakpoint(large) {
    width: 300px;
    position: fixed;
    top: 4.75rem;
    right: 1rem;
    padding: 1rem 0.75rem;
    font-size: 15px;
    border-radius: 5px;
  }

  a {
    color: inherit;
  }
}

// C) Temporäre Sachen (Aktionen usw)
// ----------------------------------

// Gutschein-Aktion
.gutschein-intro {
  @include breakpoint(medium) {
    height: 500px;
  }
}
.gutschein-wabe {
  position: absolute;
  top: 140px;
  left: 50%;
  padding: 5.25rem 1rem;
  width: 270px;
  height: 315px;
  background-size: contain;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: $header-font-family;
  opacity: 1;
  transform: translateX(-50%);
  @include breakpoint(medium) {
    width: 370px;
    height: 430px;
    padding: 6.5rem 1rem;
    top: 180px;
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: url("/assets/gfx/wabe.svg") no-repeat;
    content: "";
    opacity: 0.7;
  }
  p {
    position: relative;
    color: $gold-light;
    font-size: 1.75rem;
    line-height: 1.3;
    @include breakpoint(medium) {
      font-size: 2.75rem;
    }
  }
  span {
    color: yellow;
    font-size: 190%;
  }
}
section.gutschein {
  padding: 4rem 1rem 2rem;
  text-align: center;
  background-color: $light-bg;

  .button {
    margin-top: 2rem;
  }
}

.gutschein-feat {
  background-color: $black;
  color: $white;
  margin-bottom: -2rem;

  @include breakpoint(medium) {
    margin-bottom: -5rem;
  }

  .feat-1,
  .feat-2 {
    min-height: 300px;
    position: relative;
    @include breakpoint(small only) {
      min-height: 400px;
    }
  }
  // Zwei absolute Container (txt + img) pro Box
  .feat-1--img,
  .feat-2--img {
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 40%;
    width: 100%;
    @include breakpoint(medium) {
      height: 100%;
      width: 50%;
    }
  }
  .feat-1--img {
    background-image: url("/assets/img/bulgarien/goldstrand/intro-s.jpg");
  }
  .feat-2--img {
    background-image: url("/assets/img/lloret.jpg");
    @include breakpoint(medium down) {
      left: auto;
      right: 0;
    }
  }

  .feat-2--txt,
  .feat-1--txt {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background: #000;
    padding: 2.5rem 0.75rem 1rem;
    @include breakpoint(small only) {
      width: 100%;
      height: 60%;
      top: auto;
      bottom: 0;
      padding-top: 1rem;
    }

    h3 {
      color: $gold-light;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
    .button {
      min-width: 180px;
      margin-top: 1rem;
    }
  }
  .feat-2--txt {
    @include breakpoint(medium down) {
      left: 0;
      right: auto;
    }
  }
}

// Gutschein-Email-Feld (special special)

section.gutschein #gutscheinEmail {
  max-width: 550px;
  height: 3rem;
  margin: 0 auto;
  position: relative;
  border-radius: 0;
  overflow: hidden;

  input[type="email"] {
    width: 65%;
    margin: 0;
    float: left;
    height: 3rem;
    @include breakpoint(medium) {
      width: 80%;
    }
  }
  .button {
    width: 35%;
    margin: 0;
    float: right;
    height: 3rem;
    padding: 0.25rem;
    @include breakpoint(medium) {
      width: 20%;
    }
  }
  &.gesendet {
    animation: gutschein-form 0.7s forwards;
    &:before {
      content: "";
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgb(59, 150, 47);
      position: absolute;
      display: block;
      z-index: 1;
      visibility: hidden;
      opacity: 0;
      animation: gutschein-overlay 0.5s forwards;
    }
    &:after {
      content: "\f00c";
      font-family: "FontAwesome";
      font-size: 2rem;
      color: #fff;
      @include absolute-center;
      visibility: hidden;
      opacity: 0;
      animation: gutschein-overlay-txt 0.5s 0.5s forwards;
      z-index: 2;
    }
    input[type="email"],
    .button {
      color: transparent;
      background: transparent;
    }
  }
}

@keyframes gutschein-form {
  from {
    width: 100%;
  }
  1% {
    border-radius: 50%;
  }
  10% {
    width: 30%;
  }
  40% {
    transform: scale(0.5);
    width: 50px;
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    width: 50px;
    border-radius: 50%;
  }
}
@keyframes gutschein-overlay {
  from {
    visibility: hidden;
    opacity: 0;
  }
  10% {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes gutschein-overlay-txt {
  from {
    visibility: hidden;
    opacity: 0;
  }
  90% {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

.deal-box {
  border: 2px solid $black;
  display: inline-flex;
  font-family: $header-font-family;
  flex-flow: column;
  text-shadow: none;
  line-height: 1.2;
  letter-spacing: 0.025em;

  @include breakpoint(medium) {
    flex-flow: row;
    font-size: 2.5rem;
  }

  &__A {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background: rgba($brand, 0.65);
    color: $white;
    border-bottom: 2px solid $black;
    font-size: 1.5rem;

    @include breakpoint(medium) {
      font-size: 2rem;
      border-bottom: none;
      border-right: 2px solid $black;
    }
    small {
      display: block;
      font-size: 60%;
    }
  }

  &__B {
    justify-content: center;
    padding: 0.25em;
    font-size: 1.5rem;
    background: none;
    color: $black;

    @include breakpoint(medium) {
      font-size: 3.25rem;
      border-bottom: none;
    }
  }
  &__B-highlight {
    color: $white;
  }
}

// Partyclub USPs für Landingpage
/*ul*/
.partyclub-usps {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  list-style: none;
  margin: 1rem auto 0;
  @include breakpoint(large) {
    justify-content: space-between;
  }
}

/*li*/
.partyclub-usp {
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  line-height: 1.2;
  padding-left: 3rem;
  @include breakpoint(medium) {
    padding-left: 0;
    text-align: center;
    padding: 0 1rem;
    width: calc((100% / 3) - 1rem);
  }
  @include breakpoint(large) {
    width: calc((100% / 5) - 2rem);
  }

  // Mobil: Checkmark
  &::before {
    content: "";
    width: 2rem;
    position: absolute;
    left: 1rem;
    top: 0;
    height: 1rem;
    background-image: url("/assets/gfx/icons/check-b.svg");
    background-size: contain;
    background-repeat: no-repeat;
    @include breakpoint(medium) {
      display: none;
    }
  }

  // ul..partyclub-usps--checkmark-color - Modifier?
  // Dann Magenta-Häkchen
  .partyclub-usps--checkmark-color &::before {
    background-image: url("/assets/gfx/icons/check-magenta.svg");
  }
  // Sonst: Icon im <img>
  img {
    height: 3rem;
    margin-bottom: 0.5rem;
    width: 100%;
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
  }
}

// Teaser unter Calculator: Anfrage 2020
.abi-2020-teaser {
  display: block;
  margin: 1.25rem auto 0.75rem;
  background: $gold;
  color: $white;
  font-size: 1.5rem;
  padding: 1.5rem 1rem;
  text-transform: uppercase;
  font-family: $header-font-family;
  text-align: center;

  @include breakpoint(medium) {
    font-size: 1.75rem;
  }

  &:hover,
  &:focus {
    color: $white;
  }

  .button {
    margin: 1rem auto 0;
  }
}

// Partyvillage: Header-Video, skaliert proportional

.partyvillage-header {
  position: relative;
  overflow: hidden;
  // Firefox rendert den Hintergrund anders als Chrome + Safari
  // Bekommt hiermit spezielle Farbe:
  background-color: #252a3c;
  @supports not (-moz-appearance: none) {
    // Allen anderen diese:
    background-color: #2a2e47;
  }

  // mobil: intrinsic ratios statt Höhe
  // Desktop: feste Höhe video mittig, mit Verlauf (Vignette) an den Seiten
  @include breakpoint(large) {
    max-height: 90vh;
    height: 720px;
    padding: 0;
  }

  &__video-wrap {
    height: 0;
    padding-bottom: 74.8%;

    @include breakpoint(large) {
      display: flex;
      margin: 0 auto;
      height: 100%;
      padding: 0;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include breakpoint(large) {
      height: 100%;
      margin: 0 auto;
      width: auto;
      position: relative;

      // Video sanft auslaufen lassen
      // &::before {
      //   content: "";
      //   position: absolute;
      //   z-index: 1;
      //   left: -2px;
      //   top: 0;
      //   width: 5rem;
      //   background: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,1.0));
      //   height: 100%;
      // }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   z-index: 1;
      //   right: -2px;
      //   top: 0;
      //   width: 5rem;
      //   background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1.0));
      //   height: 100%;
      // }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include breakpoint(large) {
        position: static;
        height: 100%;
        // width: auto;
        // TODO: width: auto klappt nicht weil safari dumm ist und die dimensionen nicht weiß
        // https://stackoverflow.com/a/14272407
        // deshalb müssen wir das innere <video> auf width:100% setzen, was auch
        // das Parnet-div über die ganze Breite gehen lässt
        width: 100%;
      }
    }
  }
}

// LP-Inhalte
.partyvillage-intro {
  text-align: center;
  padding-top: 0.5rem;
  @include breakpoint(medium) {
    padding-top: 1rem;
  }

  &__headline {
    text-transform: none;
    font-size: 1.625rem;
    margin-bottom: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 0;
    position: relative;

    @include breakpoint(medium) {
      font-size: 2.125rem;
      // padding für die Pfeile
      padding-left: 4rem;
      padding-right: 4rem;
      padding-bottom: 2.5rem;
    }

    &::before,
    &::after {
      content: "";
      width: 3rem;
      height: 3rem;
      position: absolute;
      right: 0;
      bottom: 1rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("/assets/img/aktionen/partyvillage/pfeil.svg");
      display: none;
      // Pfeile nur ab Desktop
      @include breakpoint(medium) {
        display: block;
      }
    }
    &::before {
      right: auto;
      left: 0;
      transform: scaleX(-1);
    }
  }

  &__links {
    display: flex;
    list-style: none;
    margin: 0;
    margin-bottom: 1.5rem;
    left: 0;
    @include breakpoint(medium) {
      display: none;
    }

    li {
      width: 50%;
      padding: 0 0.25rem;
      font-weight: 400;
      margin-bottom: 0.25rem;
      font-size: 1.125rem;

      a {
        color: $black;
      }

      p {
        margin-top: 0.5rem;
        margin-bottom: 0;
        line-height: 1.4;
      }

      // Preis
      .product-price {
        font-size: 80%;
      }

      .pseudo-link {
        color: $anchor-color;
        margin-top: 0.25rem;
        font-weight: 400;
        font-size: 80%;
      }
    }
  }
}

.partyvillage-stars {
  list-style: none;
  margin: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 1.5rem;
    margin-right: 0.25rem;
  }

  // über JS gesetzt wenn das Video einmal durchgelaufen ist
  &.is-animated {
    img {
      animation: partyStars 0.5s;

      &:nth-of-type(1n + 1) {
        animation-delay: 0;
      }
      &:nth-of-type(2n + 2) {
        animation-delay: 0.15s;
      }
      &:nth-of-type(3n + 3) {
        animation-delay: 0.3s;
      }
      &:nth-of-type(4n + 4) {
        animation-delay: 0.45s;
      }
    }
  }
}

@keyframes partyStars {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.25, 1.25, 1.25);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

/*ul*/
.partyvillage-teasers {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

/*li*/
.partyvillage-teaser {
  margin-bottom: 1.5rem;
  // üer ganze Breite, ins Foundation-Padding ziehen
  width: calc(100% + 0.625rem + 0.625rem);
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  @include breakpoint(medium) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    width: calc(50% - 1rem);
  }

  @include breakpoint(large) {
    width: calc(50% - 2.5rem);
  }

  > a {
    color: inherit;
  }

  &__img {
    height: 300px;
    background-size: cover;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: $white;
    font-family: $header-font-family;
    font-size: 2rem;
    position: relative;
    @include breakpoint(medium) {
      font-size: 2.5rem;
      height: 400px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
      background: radial-gradient(
        ellipse at center,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.1) 100%
      );
      z-index: 0;
    }

    .partyvillage-stars {
      position: relative;
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        height: 3rem;
        @include breakpoint(large) {
          height: 4rem;
        }
      }
    }
  }

  &__heading {
    position: relative;
    font-family: $header-font-family;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0;
    text-align: center;
    font-size: 2rem;
    @include breakpoint(large) {
      font-size: 3rem;
    }
  }

  &__cta {
    position: relative;
    cursor: pointer;
    // background: lighten($brand, 5%);
    margin-top: 2.5rem;
    margin-bottom: 0;
    background: #ec3398;
    padding-left: 3em;
    padding-right: 3em;
    font-size: 0.9375rem;
    &:hover,
    &:focus {
      background: $brand;
    }

    @include breakpoint(large) {
      margin-top: 4rem;
      font-size: 1rem;
    }
  }

  // Preis unterhalb
  &__text {
    margin-bottom: 0;
    padding: 0.25rem 0.625rem;
    text-align: center;
    @include breakpoint(medium) {
      .product-price {
        font-size: 120%;
      }
    }
  }
}

.partyvillage-subheadline {
  font-family: $header-font-family;
  font-weight: 400;
  color: $black;
  letter-spacing: 0;
  line-height: 1.2;
  font-size: 1.75rem;
  margin-bottom: 0.75rem;
  @include breakpoint(large) {
    font-size: 2rem;
  }
}

// Textbereich unter den Teaser. Sonderklassen um den Text mit den Teasern
// bündig platzieren zu können
.partyvillage-usps {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  max-width: 1400px;
  margin: 0 auto;
}

.partyvillage-usp {
  width: 100%;
  padding: 0 0.6325rem;
  margin-bottom: 2rem;
  @include breakpoint(medium) {
    padding: 0 1.5rem;
    width: calc(50% - 1.5rem);
  }
}

// Teaser auf Produktseite
.partyvillage-banner {
  overflow: hidden;
  // mobil: ganzflächig, ins Foundation-Padding ziehen
  width: calc(100% + 0.6325rem + 0.6325rem);
  margin-left: -0.6325rem;
  margin-right: -0.6325rem;
  position: relative;
  padding-bottom: 0.5rem;
  @include breakpoint(medium) {
    width: 100%;
    margin: 0;
  }

  &__inner {
    display: flex;
    // justify-content: center;
    width: 100%;
    padding: 0.5rem 1.25rem 0.25rem;
    // padding-top: 2rem;
    padding-top: 7%;
    background: #242a3e;
    color: $white;
    transform: skewY(-4deg);
    transform-origin: bottom left;
    @include breakpoint(medium) {
      padding-top: 7%;
    }
  }

  // typo als SVG
  &__typo {
    height: auto;
    width: 90%;
    max-width: 400px;
    // die Typo im SVG ist auch noch mal gedreht. Hier einfach zurückdrehen, dann müssen wir das nicht neu anlegen
    transform: skewY(3deg);
    margin-bottom: 0;

    @media screen and (min-width: 800px) {
      max-width: none;
      margin-bottom: -0.5rem;
    }
  }
}

// Abi-2020: Neue StartseitenAnimation
.intro-2020 {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0,10,20,.12);
    z-index: 0;
    animation: fade-in 0.75s 0.5s both;
  }

  &__title {
    // opacity: 0;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
    animation: bounceInDown 0.95s 2.25s both;

    img {
      width: 80%;
      max-width: 450px;
      @include breakpoint(large) {
        max-width: 600px;
      }
    }
  }

  &__price-wrap {
    z-index: 2;
    animation: bounceInUp 0.95s 2.25s both;
  }

  &__price {
    display: none;
    background: $anthrazit;
    color: $white;
    display: flex;
    font-family: $header-font-family;
    align-items: flex-start;
    line-height: 1;
    font-weight: 600;
    justify-content: center;
    position: relative;
    padding: 0.75rem;
    font-size: 2rem;
    transform: rotate(-7deg);
    box-shadow: 0 2px 15px rgba(0,0,0,0.2);

    @include breakpoint(medium) {
      font-size: 3.5rem;
    }

    small {
      font-size: 50%;
      padding-top: 0.25em;
      margin-right: 0.25em;
    }
  }

  &__overlay {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.98;
    backdrop-filter: blur(1px);
    width: 100%;
    height: 100%;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: 500%;
    animation: intro2020Background 0.5s 1.5s cubic-bezier(.92,.05,.94,.78) both;
    will-change: transform;
    transform: translateZ(0);

    @include breakpoint(medium) {
      background-size: 350%;
    }
    @include breakpoint(large) {
      background-size: 250%;
    }
    // background-size: 150vw;
  }
}

@keyframes intro2020Background {
  50% {
    // opacity: 0.5;
    // transform: scale(3) rotate(-6deg);
  }
  100% {
    opacity: 0;
    pointer-events: none;
    transform: scale(10) rotate(-25deg) translateZ(0);
  }
}

// LP 2021: Custom Intro-Bereich
.intro-2021 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;


  &__title {
    width: auto;
    font-family: $header-font-family;
    font-size: 3rem;
    color: $white;
    padding: 0 1.5rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    @include breakpoint(medium) {
      font-size: 4rem;
    }
    @include breakpoint(large) {
      font-size: 6rem;
    }
    strong {
      font-weight: 600;
      font-size: 120%;
    }
  }

  // im __title
  &__icon {
    width: 100%;
    text-align: right;

    img {
      width: 3.5rem;
      height: 3.5rem;
      margin-bottom: -0.5rem;
      margin-right: -1.5rem;
      @include breakpoint(medium) {
        margin-bottom: -3rem;
        margin-right: -3rem;
        width: 5rem;
        height: 5rem;
      }
    }
  }


  &__cta {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    color: $white;
    @include breakpoint(medium) {
      font-size: 1.25rem;
    }

    &:hover,
    &:focus {
      color: $white;
    }

    .button {
      margin-top: 1rem;
      font-size: 1rem;
      @include breakpoint(medium) {
        font-size: 1.25rem;
      }
    }
  }
}