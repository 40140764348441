// -----------------------------------------------------------------------------
// *** READMORE *** 
// -----------------------------------------------------------------------------
// Readmore-Button: Text erscheint erst komplett nach Klick
// -----------------------------------------------------------------------------

.readmore {
    position: relative;
    overflow: hidden;
    // transition: all 0.25s;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba($white, 0.0), rgba($white, 0.5) 30%, rgba($white, 1.0) 70%);
        height: 7rem;
        z-index: 1;

        // bei nicht-weißem Hintergrund: Verlauf anpassen
        .light-bg & {
            background: linear-gradient(to bottom, rgba($light-bg, 0.0), rgba($light-bg, 0.5) 30%, rgba($light-bg, 1.0) 70%);
        }

        .very-light-bg & {
            background: linear-gradient(to bottom, rgba($very-light-bg, 0.0), rgba($very-light-bg, 0.5) 30%, rgba($very-light-bg, 1.0) 70%);
        }
    }

    &.is-inactive{
        padding-bottom: 0;
         &:after {
            content: none;
        }
    }

    .readmore__less, 
    .readmore__more {
        @include horizontal-center;
        bottom: 0.5rem;
        z-index: 2;
    }

    .readmore__less {
        display: none;
    }

    &.is-expanded {
        transition: all 0.25s;
        // unten padding für "weniger lesen" (absolut pos.)
        padding-bottom: 2rem;
        
        .readmore__less {
           display: block;
        }

        .readmore__more{
           display: none;
        }

        &:after {
            content: none;
        }
    }
}

