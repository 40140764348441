//  GENERAL - Generelles, Farben, Fonts, etc
// ---------------------------

// Farben
$brand              : #e6007e;
$brand-secondary    : #A00057;
$light-bg           : #e7d6ba;
$very-light-bg      : #f3f2f1;
$dark-bg            : #43667C;
$signal             : #f6ce2e;
$icon-color         : #525252; 

$gold         : #c2aa81;
$gold-dark    : #b1986d;
$gold-light   : #d8c3a6;
$anthrazit    : #33363b;
$brown        : #9d6007;

$gradient-1   : linear-gradient(35deg, $gold-light, $gold);