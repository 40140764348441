/* HOME - Startseiten - Elemente*/
/* temporäre Sonderaktionen besser in die _special.scss */
/* -------------------------------------------*/

/* Bild*/
.start-img {
    background-position: top center;
    background-size: cover;
    position: relative;
    text-align: center;
    height: 350px;
    @include breakpoint(medium) {
        height: 540px;
    }
    @include breakpoint(xlarge) {
        height: 650px;
    }

    &.large {
        height: 450px;
        @include breakpoint(medium) {
            height: 600px;
        }
        @include breakpoint(xlarge) {
            height: 670px;
        }
    }
    &.small {
        height: 250px;
        @include breakpoint(medium) {
            height: 450px;
        }
        @include breakpoint(xlarge) {
            height: 500px;
        }
    }
}

/* Animation*/
#tw_start_overlay {
    @include coverer;
    width: 0;
    max-width: 800px;
    overflow: hidden;
    @include horizontal-center;
    background: rgba(0, 0, 0, 0.75);
    color: $white;
    text-align: center;
}
#tw_overlay_content {
    -webkit-perspective: 400;
    perspective: 400;
    display: inline-block;
    padding-top: 2rem;
    opacity: 1;
    @include breakpoint(large) {
        padding-top: 2rem;
    }
    @include breakpoint(large) {
        padding-top: 3.5rem;
    }

    img {
        width: 70px;
        @include breakpoint(medium) {
            width: 120px;
        }
    }
    .button {
        @include breakpoint(small only) {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }
    }
}
#tw_start_text {
    font-family: $header-font-family;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 1rem;
    line-height: 1.2;
    font-weight: bold;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    .h1 {
        font-size: 2rem;
        line-height: 1.0;
        margin-bottom: 0.5rem;
        font-weight: 400;
        font-family: $header-font-family;
        @include breakpoint(medium) {
            font-size: 3.75rem;
        }
    }
    .h2 {
        font-size: 1rem;
        font-weight: bold;
        @include breakpoint(medium) {
            font-size: 1.5rem;
        }
    }
}

#tw_start_list {
    list-style: none;
    margin-top: 1rem;
    li {
        color: $white;
        text-transform: none;
        letter-spacing: 1px;
        font-weight: 300;
        font-size: 0.875rem;
        font-family: $body-font-family;
        @include breakpoint(medium) {
            font-size: 1.125rem;
        }
        span {
            font-weight: 700;
            color: $signal;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 180%;
            font-family: $header-font-family;
        }
    }
}

#tw_start_button {
    margin-top: 0.5rem;
    opacity: 1;
    border: 2px solid $white;
    line-height: 1.4;
    max-width: 90%;
    padding: 0.5rem 2.5rem;
    &:hover {
        color: $white;
        border-color: $white;
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
    }
    @include breakpoint(medium) {
        margin-top: 1rem;
    }
}

.video {
    margin-bottom: 1rem;
}

/* Call-to-action*/
/*ul*/.cta-items {
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    list-style: none;
    margin: 0;
}

/*li*/.cta-item {
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    color: $black;
    background-color: $white;
    flex-flow: column;
    padding: 1.5rem 1rem;
    @include breakpoint(medium) {
        padding: 2.5rem 1rem;
        width: calc(100% / 4);
    }

    &__top {
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        
        @include breakpoint(medium) {
            padding: 1.5rem;
        }
        
        i,
        img,
        svg,
        span {
            height: 45px;
            width: auto;
            @include breakpoint(medium) {
                height: 70px;
            }
        }
        
        // Anfrage-Rakete größer, mit neg. MArgin ausgleichen damit alles 
        // auf einer Linie bleibt
        #svgRocket {
            height: 55px;
            margin-top: -10px;
            @include breakpoint(medium) {
                height: 90px;
                margin-top: -20px;
            }
        }
    }

    /* Animierte Zahl (Link auf Timeline)*/
    &--countdown .cta-item__top span {
        font-size: 2rem;
        display: block;
        border-radius: 50%;
        border: 3px solid $gold;
        font-family: $header-font-family;
        color: $gold;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.3;
        @include breakpoint(medium) {
            font-size: 3rem;
            border-width: 4px;
            font-weight: 500;
        }
    }

    &__header {
        text-transform: uppercase;
        font-family: $header-font-family;
        color: $gold-dark;
        font-weight: 400;
        // font-size: 1.75rem;
        // Typo: wie h3
        font-size: rem-calc(26);
        letter-spacing: 0.05em;
        line-height: 1;
        margin-bottom: 1rem;
        @include breakpoint(large) {
            font-size: rem-calc(32);
            // font-size: 2.25rem;
        }
    }

    &__subheader {
        // letter-spacing: 0.05em;
        // text-transform: uppercase;
        // typo wie h5/h6
        font-size: rem-calc(18);
        font-weight: 400;
        line-height: 1.2;
        font-family: $header-font-family;
        @include breakpoint(medium) {
            font-size: rem-calc(19);
        }
    }

    &__link {
        text-align: center;
        margin-top: auto;
        margin-bottom: -1rem;

        .button {
            margin-top: 1.5rem;
            min-width: 200px;
            @include breakpoint(medium) {
                min-width: 140px;
                padding-left:1em;
                padding-right:1em;
            }
            @include breakpoint(large) {
                min-width: 200px;
            }
        }
    }
    
    &--special {
        background-color: $brand;
        color: $white;

        .cta-item__subheader,
        .cta-item__header {
            color: $white;
        }

        //spezieller Hover, weil auf der brand-farbe
        .button:hover {
            background-color: $white !important;
            color: $brand !important;
        }
    }
}

// SVG-Rakede (bei Anfrage)
#svgRocket {
    animation: rocket-movement 2s ease alternate infinite both;

    circle,
    path {
        fill:none;
        stroke:#fff;
        stroke-width:13;
        stroke-linecap:round;
        stroke-miterlimit:10
    }
    // pfad mit der Flamme
    .rocket-flame{
        animation: rocket-flame 0.05s ease alternate infinite both;
        transform-origin: center;
    }
}

@keyframes rocket-flame {
    0% {
        transform: scale(0.96);
    }
    100% {
        transform: scale(1.02);
    }
}

@keyframes rocket-movement {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(2px);
    }
}