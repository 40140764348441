// -----------------------------------------------------------------------------
// TABLES
// -----------------------------------------------------------------------------
// Diverse Tabellenstyles
// -----------------------------------------------------------------------------

table {
    .larger {
        font-size: 115%; 
    }

    .no-border {
        border: none !important;
    }
}
// Standard
table.table {
    tbody th,
    tbody td {
        padding: 0.325rem 0.5rem 0.325rem;
        font-size: 0.8125rem;
        vertical-align: top;
    }
    tbody tr:nth-child(even) {
        background: #f9f9f9;
    }
    thead th,
    thead td,
    tfoot th,
    tfoot td {
        padding: 0.325rem 0.5rem 0.325rem;
        font-size: 0.925rem;
        line-height: 1.3;
    }
    caption {
        font-size: 0.925rem;
        text-align: left;
        font-weight: normal;
        text-transform: uppercase;
        padding: 0.325rem 0.5rem 0.325rem;
    } // Tabellen für mobil responsiv/scrollbar
    @include breakpoint(small only) {
        display: block;
        width: 100%;
        overflow-x: auto;
    }
}

table.table--vip-special {
    color: #000;

    tbody th,
    tbody td {
        padding: 0.25rem;
        font-size: 0.8125rem;
        vertical-align: top;
    }

    thead th,
    thead td {
        padding: 0.25rem;
        font-size: 0.9375rem;
        vertical-align: top;
        text-align: center;
        background: #fcf0c2;
        text-transform: uppercase;
    }
    tbody tr:nth-child(even) {
        background: #fcf0c2;
    }
}


table.table--width-auto {
    width: auto; // letzte Tabellenzellen mit mehr padding nach link
    tr td:last-child {
        padding-left: 1.25rem;
    }
}

table.table--simple {
    border-collapse: nocollapse;
    font-size: 0.9375rem;
    @include breakpoint(medium) {
        font-size: 1rem;
    }
    tr {
        background: $white;
        td {
            padding: 0.375rem;
            line-height: 1.1;
            vertical-align: top;
        }
    }
    tbody tr:nth-child(even) {
        background-color: $white;
    }
    thead,
    tbody,
    tfoot {
        border: none;
    }
    thead {
        border-bottom: 1px solid $black;
    }
    tfoot {
        border-top: 1px solid $black; // text-transform: uppercase;
    }
    thead th {
        padding-bottom: 2px;
        line-height: 1.2;
    }
    select {
        font-size: 0.9375rem;
        height: auto;
        padding-top: 0.325em;
        padding-bottom: 0.325em;
        @include breakpoint(medium) {
            font-size: 1rem;
        }
    }
    .amount {
        max-width: 30px;
    }
}

