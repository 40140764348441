// MAP - interaktive Google-Map
// -----------------------------

#googleMap {
  width: 100%;
  height: 350px;
  position: relative;
  @include breakpoint(medium) {
    height: 450px;
  }
}

/* Map-Buttons */
.map-control {
  list-style: none;
  margin: 1rem 0 0.25rem;
  li {
    display: inline-block;
    margin: 0 0.75rem 1rem;
    padding: 0;
  }
  a {
    color: #333;
    border: 1px solid transparent;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.75rem;
    position: relative;
    -webkit-tap-highlight-color: transparent;

    &.active {
      color: $white;
      &#mm_hotel {
        background-color: #e6007e;
      }
      &#mm_beachpoint {
        background-color: #006a8d;
      }
      &#mm_disco {
        background-color: $anthrazit;
      }
      &#mm_shopping {
        background-color: $brand-secondary;
      }
      &#mm_restaurant {
        background-color: #C2AA81;
      }
    }
    &:before {
      font-family: FontAwesome;
      font-size: 1.125rem;
      padding-right: 0.3rem;
    }
  }
  #mm_hotel:before {
    content: '\f015';
  }
  #mm_beachpoint:before {
    content: '\f140';
  }
  #mm_disco:before {
    content: '\f186';
  }
  #mm_restaurant:before {
    content: '\f0f5';
  }
  #mm_shopping:before {
    content: '\f290';
  }
}
// Map-Hinweise/Popups
.infobulle {
  overflow: hidden;
  cursor: default;
  clear: both;
  position: relative;
  height: 22px;
  padding: 0;
  background-color: $anthrazit;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  .text {
    color: #fff;
    font-family: Verdana;
    font-size: 12px;
    line-height: 15px;
    padding: 1px 14px;
    white-space: nowrap;
    margin-top: 2px;
    max-width: 220px;
  }
  &.hotel {
    background-color: #e6007e;
  }
  &.beachpoint {
    background-color: #006a8d;
  }
  &.shopping {
    background-color:  $brand-secondary;
  }
  &.restaurant {
    background-color: #C2AA81;
    .text {
      color: $black;
    }
  }
}
.arrow {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  height: 0;
  width: 0;
  border: inset 10px;
  border-color: $anthrazit transparent transparent;
  border-style: solid;
  &.hotel {
    border-color: #e6007e transparent transparent;
  }
  &.beachpoint {
    border-color: #006a8d transparent transparent;
  }
  &.shopping {
    border-color: $brand-secondary transparent transparent;
  }
  &.restaurant {
    border-color: #C2AA81 transparent transparent;
  }
}
