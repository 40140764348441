// -----------------------------------------------------------------------------
// #TEASER
// -----------------------------------------------------------------------------

/* Startseite: Special-Boxen*/

/*ul*/
.boxes {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

/*li*/
.box {
  width: 100%;
  text-align: center;
  background: $black;
  font-family: $header-font-family;
  text-transform: uppercase;
  overflow: hidden;
  margin-bottom: 1.5rem;
  display: flex;
  flex-flow: column;

  @include breakpoint(medium) {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    width: calc((100% / 2) - 1.5rem);
  }

  @include breakpoint(large) {
    width: calc((100% / 3) - 1.5rem);
  }

  &--gold {
    background: linear-gradient(to bottom, $light-bg 0%, $gold-light 75%);

    .box__text {
      color: $black;

      p {
        color: $black;
      }
    }
  }
}

.box__img {
  position: relative;

  img {
    width: 100%;

    // feste Höhe bis bilder geladen
    &.lazyload,
    &.lazyloading {
      height: 300px;
    }
  }

  a {
    color: currentColor;

    &.active,
    &:hover {
      color: currentColor;
    }
  }
}

.box__text {
  color: $white;
  padding: 1rem;
  position: relative;
  font-weight: 400;

  h3 {
    line-height: 1.1;
    font-weight: 400;
    margin-bottom: 1.25rem;
    letter-spacing: 0.05em;
  }

  p {
    color: $gold-light;
    line-height: 1.3;
    font-size: 0.9375rem;
    font-family: $body-font-family;
    text-transform: none;
  }
}

.box__link {
  display: block;
  margin-top: auto;
  margin-bottom: 1.5rem;
  text-align: center;

  .button {
    margin: 0;
  }
}

//------------------------------------------------------------------------------
// Product-Teasers
//------------------------------------------------------------------------------
/*ul*/
.product-teasers {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

/*li*/
.product-teaser {
  background: $black;
  color: $white;
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: calc((100% / 2) - 0.5rem);
  font-size: 0.875rem;
  display: flex;
  flex-flow: column;

  // der äußerste Teaser ohne Margin rechts (mobil: jeder zweite)
  &:nth-child(2n + 2) {
    margin-right: 0;
  }

  @include breakpoint(large) {
    font-size: 1rem;
    margin-bottom: 1.25rem;
    margin-right: 1.5rem;
    width: calc((100% / 3) - 1rem);

    // der äußerste Teaser ohne Margin rechts (desktop: jeder dritte)
    &:nth-child(2n + 2) {
      margin-right: 1.5rem;
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 180px;
    background-position: center;
    background-size: cover;

    @include breakpoint(medium) {
      height: 220px;
    }

    @include breakpoint(large) {
      height: 300px;
    }

    // Link ganzflächig über dem Bild
    >a {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  // Pseudobutton (Link liegt auf dem ganzen Bild, nicht dem Button)
  &__button {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.875em 1.625em;
    line-height: 1;
    text-transform: uppercase;
    font-size: 80%;
    background: $white;
    color: $black;
    font-weight: 400;
    letter-spacing: 0.08em;
    font-family: $header-font-family;
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.3);
    min-width: 9em;
    text-align: center;

    @include breakpoint(medium) {
      font-size: 0.875rem;
    }
  }

  &__text {
    padding: 1rem;
    text-align: center;
    display: flex;
    flex: 1 0 auto;
    flex-flow: column;
    // height: 100%;
    width: 100%;
    line-height: 1.3;
    font-weight: 400;

    p {
      // color: $gold-light;
      font-size: rem-calc(15);
      opacity: 0.8;
      line-height: inherit;
    }

    h3 {
      text-transform: uppercase;
      line-height: 1.2;
      font-size: 135%;
      width: 100%;
      font-weight: 400;

      small {
        text-transform: none;
      }
    }

    a {
      font-weight: 400;
      margin-top: auto;
      color: lighten($anchor-color, 25%);

      &:focus,
      &:hover {
        color: lighten($anchor-color, 20%);
      }
    }
  }
}

//------------------------------------------------------------------------------
// Teasers (praktisch Product-Teaser in neuem Design, ab 2020
//------------------------------------------------------------------------------
/*ul*/
.teasers {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

/*li*/
.teaser {
  background: $black;
  color: $white;
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: calc((100% / 2) - 0.5rem);
  font-size: 0.875rem;
  display: flex;
  flex-flow: column;

  >a {
    height: 100%;
    width: 100%;
    display: inherit;
    flex-flow: inherit;
    align-items: inherit;
    justify-content: inherit;
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  // der äußerste Teaser ohne Margin rechts (mobil: jeder zweite)
  &:nth-child(2n + 2) {
    margin-right: 0;
  }

  @include breakpoint(large) {
    font-size: 1rem;
    margin-bottom: 1.25rem;
    margin-right: 1.5rem;
    width: calc((100% / 3) - 1rem);

    // der äußerste Teaser ohne Margin rechts (desktop: jeder dritte)
    &:nth-child(2n + 2) {
      margin-right: 1.5rem;
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 180px;
    background-position: center;
    background-size: cover;

    @include breakpoint(medium) {
      height: 220px;
    }

    @include breakpoint(large) {
      height: 300px;
    }

    &::after {
      content: "";
      position: absolute;
      @include coverer;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &__img-title {
    position: absolute;
    top: 18%;
    right: 50%;
    white-space: nowrap;
    z-index: 1;
    transform: rotate(-6deg) translateX(50%);
    color: $white;
    font-family: $header-font-family;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    @include fluid-type(1px, 1000px, 14px, 40px);
    text-align: right;

    small {
      text-transform: none;
      display: block;
      font-size: 65%;
    }
  }

  // Pseudobutton (Link liegt auf dem ganzen Teaser)
  &__button {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.875em 1.625em;
    line-height: 1;
    text-transform: uppercase;
    font-size: 80%;
    background: $white;
    color: $black;
    font-weight: 400;
    letter-spacing: 0.08em;
    font-family: $header-font-family;
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.3);
    min-width: 9em;
    text-align: center;

    @include breakpoint(medium) {
      font-size: 0.875rem;
    }
  }

  &__title {
    font-family: $header-font-family;
    padding: 1rem 0.75rem;
    margin: 0;
    line-height: 1.1;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;

    @include breakpoint(medium) {
      font-size: 1.5rem;
    }
  }

  &__price {
    margin-top: auto;
    text-align: center;
    font-weight: 400;
    line-height: 1.2;
    font-family: $header-font-family;
    padding: 0.5rem 0.75rem 1rem;
    font-size: 1rem;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 63%;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
    }

    strong {
      font-size: 125%;

      @include breakpoint(medium) {
        font-size: 150%;
      }
    }

    small {
      display: block;
      color: hsla(0, 0, 100, 0.75);
    }

    @include breakpoint(medium) {
      font-size: 1.25rem;
    }
  }

  &__cta {
    text-align: center;
    padding: 0.25rem 0.75rem 0.875rem;

    @include breakpoint(medium) {
      padding: 0.5rem 0.75rem 1.325rem;
    }

    .button {
      margin: 0;
      font-size: 0.625rem;
      padding-left: 1em;
      padding-right: 1em;

      @include breakpoint(medium) {
        font-size: 0.875rem;
      }
    }
  }
}

.teaser.teaser--partyvillage {
  // display: none;
  background: hsl(229, 24%, 22%);

  .teaser-partyvillage__title {
    padding: 2rem 0.25rem 1rem;
    transform: rotate(-6deg);
    color: $white;
    font-family: $header-font-family;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    @include fluid-type(1px, 1000px, 12px, 40px);
    text-align: center;

    @include breakpoint(medium) {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  .teaser-partyvillage__icon {
    text-align: center;
    display: flex;
    align-items: center;
    transform-origin: center;
    justify-content: center;

    img {
      height: 2.5rem;

      @include breakpoint(medium) {
        height: 4rem;
      }
    }
  }

  /*ul*/
  .teaser-partyvillage__usps {
    position: relative;
    height: 3.5rem;
    margin: 0;
    list-style: none;
    text-align: center;
    font-weight: 400;
    padding: 0.25rem 0.75rem;

    @include breakpoint(medium) {
      height: 4.5rem;
    }

    li {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
      top: 0rem;
      padding: 0.25rem;
      line-height: 1.2;
      opacity: 0;
      position: absolute;
      visibility: hidden;

      @include breakpoint(medium) {
        padding-top: 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    // Anfangs nur den ersten usp-Punkt zeigen, die restlichen per
    // GSAP einblenden
    // li:not(:first-of-type) {
    //     display: none;
    //     position: absolute;
    // }
  }

  // Preis hier ohne border
  .teaser__price::after {
    display: none;
  }
}

// Special für "Preiskracher Calella"
.teaser.teaser--preiskracher {
  position: relative;

  .teaser__img {
    height: 100%;
  }

  .teaser__price {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    // ohne border oben
    &::after {
      display: none;
    }
  }

  .teaser__img-title {
    white-space: normal;
    top: 1.5em;
    width: 100%;
    line-height: 1;
    text-align: center;

    // Destiname in small
    small {
      display: block;
      margin-top: 0.25rem;
      // optisch mittig (die Hotelsterne rechts)
      margin-right: -0.5rem;
    }

    // Unterzeile, "nur 29.05..."
    strong {
      color: $brand;
      background: $white;
      text-transform: none;
      display: inline-block;
      padding: 0.25em 0.5em 0.325em;
      font-size: 65%;
      white-space: nowrap;
      line-height: 1;
      margin: 0.5rem 0;
    }

    // Kreis mit Preis
    em {
      border-radius: 50%;
      font-size: 80%;
      // Gegenrotation
      transform: rotate(6deg);
      background: $brand;
      color: $white;
      line-height: 1;
      height: 4.5em;
      width: 4.5em;
      text-transform: none;
      box-shadow: 0 2px 0.5rem rgba(0, 0, 0, 0.3);
      font-style: normal;
      text-align: center;
      display: flex;
      margin: 0.75rem auto;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.325em;
    }
  }
}

// Special für "Abi 2020"-LP
.teaser.teaser--2020 {
  position: relative;

  .teaser__img {
    height: 100%;
  }

  .teaser__cta {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    // ohne border oben
    &::after {
      display: none;
    }
  }

  .teaser__img-title {
    left: 50%;
    transform: rotate(0deg) translateX(-50%);
    @include fluid-type(1px, 1000px, 20px, 60px);
    white-space: normal;
    top: 0.325em;
    text-transform: none;
    width: 100%;
    line-height: 1;
    text-align: center;


    // Kreis mit Preis
    em {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      align-content: center;
      justify-content: center;
      border-radius: 50%;
      @include fluid-type(1px, 1000px, 10px, 20px);
      background: $brand;
      color: $white;
      line-height: 1.2;
      height: 6.5em;
      width: 6.5em;
      text-transform: none;
      box-shadow: 0 2px 0.5rem rgba(0, 0, 0, 0.3);
      font-style: normal;
      text-align: center;
      display: flex;
      margin: 1.25rem auto 0.75rem;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.325em;

      @include breakpoint(medium) {
        margin-top: 2rem;
      }

      // Prozent in strong
      strong {
        width: 100%;
        line-height: 1;
        margin-top: 0.1em;
        text-align: center;
        font-size: 200%;
      }
    }
  }
}

.teaser.teaser--corona-info {
  background: $white;
  color: $black;

  .teaser__img {
    padding-top: 1.5rem;
    padding-bottom: 0.75rem;
    height: auto;
    text-align: center;

    @include breakpoint(medium) {
      padding-top: 3rem;
      padding-bottom: 1.5rem;
    }

    &::before,
    &::after {
      display: none;
    }

    img {
      width: 5rem;

      @include breakpoint(medium) {
        width: 6rem;
      }
    }
  }

  .teaser__title {
    margin-top: auto;

    small {
      display: block;
      line-height: 1.5;
      font-size: 80%;
      color: $black;
      margin-top: 1rem;
    }
  }

  .teaser__cta {
    margin-top: auto;
  }
}

// Karten für Versicherungen (Basis- + Komplettpaket)
/*ul*/
.cards {
  margin: 0 0 1rem;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
}

/*li*/
.card {
  width: 100%;
  flex: 1 0 auto;
  max-width: 400px;
  position: relative;
  margin-bottom: 0.75rem;
  padding: 1.25rem;
  background: $white;
  // box-shadow: 0 0.25rem 1rem rgba(0,0,0, 0.1);
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.06);
  color: $black;

  @include breakpoint(medium) {
    padding: 1.5rem;
    width: 50%;
  }

  &--highlight {
    z-index: 1;
    // mobil: Das Versicherungskomplett-Paket ganz nach oben ziehen
    order: -1;

    @include breakpoint(medium) {
      order: unset;
    }
  }
}

// Versicherungskarten
/*ul*/
.insurance-cards {
  // oben extra margin, für überstehendes Bubble
  margin: 2rem 0 0;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  align-items: flex-start;
}

/*li*/
.insurance-card {
  width: 100%;
  flex: 1 0 auto;
  max-width: 400px;
  position: relative;
  margin-bottom: 0.75rem;
  background: $white;
  // box-shadow: 0 0.25rem 1rem rgba(0,0,0, 0.1);
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.06);
  color: $black;

  @include breakpoint(medium) {
    width: calc(50% - 0.5rem);
    margin-right: 0.5rem;
  }

  &--highlight {
    z-index: 1;
    // mobil: Das Versicherungskomplett-Paket ganz nach oben ziehen
    order: -1;

    @include breakpoint(medium) {
      order: unset;
    }
  }

  &__header {
    position: relative;
    padding: 1rem;
    padding-right: 5rem;
    background: rgba($anthrazit, 0.85);
    color: $white;

    h3 {
      font-weight: 500;
      margin-bottom: 0;
      line-height: 1.3;
    }
  }

  &__bubble {
    position: absolute;
    top: -1.25em;
    right: -0.5em;
    font-family: $header-font-family;
    background: $brand;
    color: $white;
    border-radius: 50%;
    width: 5.5em;
    text-align: center;
    height: 5.5em;
    padding: 0.5em;
    display: inline-flex;
    font-weight: 500;
    font-size: 1rem;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
    line-height: 1.1;

    @include breakpoint(medium) {
      font-size: 1.125rem;
    }
  }

  &__list {
    list-style: none;
    margin: 0.5rem 0;

    li {
      padding: 0.5rem 1rem;
      padding-left: 3.25rem;
      border-bottom: 1px solid rgba($black, 0.05);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 1.25em;
        top: 0.875em;
        width: 1em;
        height: 1em;
        background: url("/assets/gfx/icons/check.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  &__footer {
    text-align: center;
    padding: 1rem 1rem 1.5rem;
  }
}

// Ab jetzt bei ruf.de. Teaser dahin:

.goodbye-teaser {
  height: 350px;
  position: relative;
  margin-bottom: 1rem;

  @include breakpoint(medium) {
    height: 460px;
  }

  &__img {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__text {
    width: 100%;
    height: 100%;
    color: $white;
    background: linear-gradient(110deg, rgba(183, 0, 92, 0.65) 15%, rgba(183, 0, 92, 0.0) 55%);
    font-family: $header-font-family;
    font-weight: 500;
    line-height: 1.5;
    position: relative;
    z-index: 1;
    padding: 1.5rem 1rem;
    font-size: 32px;

    @include breakpoint(medium) {
      padding: 2rem;
      font-size: 44px;
    }
  }

  &__cta {
    // Stretch link
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 2rem;
    position: absolute;
    @include coverer;
    z-index: 1;

    .button {
      &:before {
        content: "";
        @include coverer;
      }
    }
  }
}