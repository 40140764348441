// -----------------------------------------------------------------------------
// *** DATEPICKER ***
// -----------------------------------------------------------------------------
// 720kb-angular-datepicker, Theme-Anpassung
// -----------------------------------------------------------------------------


#calculator {
    
    // container
     ._720kb-datepicker-calendar  {
         box-shadow: 0 4px 35px rgba(0, 0, 0, 0.16);
         z-index: 3;
         font-weight: 400;
         border-radius: 0;
         // overflow visible für css-dreieck/preil auf input-feld. Testen ob das so geht
         overflow: visible;
     }
     // css-dreieck
     ._720kb-datepicker-calendar-header:before {
         content: "";
         position: absolute;
         width: 0;
         height: 0;
         border-left: 15px solid transparent;
         border-right: 15px solid transparent;
         border-bottom: 13px solid $gold-dark;
         top: -9px;
         left: 15%;
         z-index: -1;
     }
     // Header
     ._720kb-datepicker-calendar-header {
         background: $gold-dark;
         font-size: 16px;
         font-weight: 400;
     }
     // Monat
     ._720kb-datepicker-calendar-month {
         font-family: $body-font-family;
         // Jahrespaginierung ebenfalls deaktivieren
         a, a span {
             font-weight: normal;
             font-size: 16px;
             pointer-events: none;
             color: $white;
             &:hover {
                 color: $white;
                 background: transparent;
             }
         }
         i {
             display: none;
         }
     }
     // Jahreszahl neben Monat
     ._720kb-datepicker-calendar-month span {
         font-size: 14px;
     }
     // Jahres-Pagniation? nicht benötigt, ausblenden
     ._720kb-datepicker-calendar-years-pagination-pages {
         display: none;
     }
     // nicht auswählbare Jahre auch ausblenden
     ._720kb-datepicker-calendar-years-pagination ._720kb-datepicker-disabled {
         display: none;
     }
     // Wochentage
     ._720kb-datepicker-calendar-days-header {
         background: transparentize($gold-dark, 0.95);
         div {
             padding: 7px 0 5px;
         }
     }
     // Kalender-Tage
     ._720kb-datepicker-calendar-day {
         font-size: 14px;
         font-weight: 700;
         background: #eee;
         // etwas kürzer + mehr margin, damit die Tage etwas quadratischer sind
         width: 11.2%;
         margin: 5px 1.5%;
         &:hover {
             background: #eee;
             color: $gold-dark;
         }
     }
     ._720kb-datepicker-calendar-day._720kb-datepicker-active {
         background: $gold-dark;
         color: $white;
     }
     // disbaled (nie buchbar)
     ._720kb-datepicker-calendar-day._720kb-datepicker-disabled, ._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover {
         background: transparent;
         color: #ccc;
         font-weight: 300;
     }
     // ausgebucht (waren mal buchbar)
     ._720kb-datepicker-calendar-day.sold-out {
         font-weight: 700;
         background: #fafafa;
         color: #ccc;
         cursor: not-allowed;
         &:hover {
             background: #fafafa;
             color: #ccc;
             font-weight: 700;
         }
     }
     // die Tage vor vorigen und nächsten Monat nicht anzeigen, also z.B. im Juli ab dem 01.Juli beginnen
     a[ng-repeat="px in prevMonthDays"], a[ng-repeat="nx in nextMonthDays"] {
         visibility: hidden;
     }
     // Pfeile rechts/links
     ._720kb-datepicker-default-button {
         font-size: 20px;
         color: white;
     }
     // Buttons-hover
     ._720kb-datepicker-calendar-header a:hover {
         background: rgba(255, 255, 255, 0.25);
     }
 }
 