// Header-Block
$topNavHeight: 60px;
$topNavHeightSticky: 60px;
$topNavLabelFont: $header-font-family, sans-serif;

header {
  position: relative;
  top: 0;
  background-color: $anthrazit;
  width: 100%;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  z-index: 10;
}

.topNav {
  position: relative;
  width: 100%;
  text-align: center;
  vertical-align: bottom;
  height: $topNavHeight;
  line-height: $topNavHeight;
  background-color: $anthrazit;
  color: $white; // important, weil das foundation-sticky den z-index sonst auf 3 setzt (reicht nicht)
  z-index: 110 !important;
  box-shadow: 0 3px 3px 0 rgba(17, 17, 17, 0.3); // Wenn Sticky:
  &.is-stuck {
    height: $topNavHeightSticky;
    line-height: $topNavHeightSticky;
    margin-bottom: 0;
  }
  &.subnav-attached {
    box-shadow: none;
  }
  .column,
  .row {
    // height:inherit, da diese Container ein paar Pixel höher waren als die Nav ( und die Subnav nicht schön daran anschließen konnte )
    height: inherit;
  }
}

.topNav_left {
  float: left;
  color: $white;
  text-align: left;
  height: inherit;
  line-height: inherit;
  vertical-align: middle; // BUGFIX: Breite ist hier nur angegeben, weil der IE + Edge SVG-Größen nicht richtig erkennen
  width: 160px;
  @include breakpoint(large) {
    width: 250px;
  }
}

.topNav-logo {
  .topNav-logo-abi {
    height: 0.925rem;
    vertical-align: middle;
    @include breakpoint(large) {
      height: 1.5rem;
    }
  }
  .topNav-logo-ruf {
    height: 2.75rem;
    vertical-align: top;
    margin-right: 0.25rem;
    box-shadow: 0 0 17px rgba(0, 0, 0, 1);
    @include breakpoint(large) {
      height: 3.25rem;
      margin-right: 0.75rem;
    }
  }
}

.topNav_right {
  font-weight: 400;
  float: right;
  color: $white;
  height: inherit;
  vertical-align: middle;
  ul {
    line-height: inherit;
    margin: 0;
    list-style: none;
    float: right;
    li {
      float: right;
    }
  }
  .dropdown {
    float: right;
    &:active,
    &:hover {
      background-color: lighten($anthrazit, 10%);
    }
    > li > a {
      line-height: inherit;
      padding: 0 20px;
      color: $gold-light;
      font-family: $topNavLabelFont;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .submenu {
      border: none;
      line-height: 1.2;
      width: 250px;
      padding: 1rem;
      box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.4);
      z-index: 1000;
      &::before {
        @include css-triangle(9px, $white, up);
        top: -8px;
        right: 45px;
        position: absolute;
      }
      li {
        text-align: left;
      }
      strong {
        color: $anthrazit;
        padding: 0.75em 0.75em 0.25em;
        display: block;
      }
      a {
        color: $anthrazit;
        padding: 0.625em 0.75em;
      }
    }
    .is-submenu-item a:hover {
      color: $primary-color;
    }
  }
}

.mainNav-label {
  display: none;
  cursor: pointer;
  font-family: $topNavLabelFont;
  letter-spacing: 0.075em;
  padding: 0 0 0 0.5rem;
  margin-right: 0.5rem;
  color: $gold-light;
  text-transform: uppercase;
  @include breakpoint(large) {
    display: block;
    float: left;
  }
}

.hotline {
  display: inline-block;
  margin: 0 auto;
  letter-spacing: 0.075em;
  color: $white;
  font-family: $topNavLabelFont;
  font-weight: 400;
  font-size: 1rem;
  @include breakpoint(large) {
    font-size: 1.0625rem;
  }
  .fa {
    font-size: 150%;
  }
  // Icon mobile: neben der Lupe und direkt verlinkt
  @include breakpoint(small only) {
    margin-right: 0.925rem;
    .fa {
      color: $white;
      font-size: 1.75rem;
      vertical-align: -15%;
    }
  }
}

#mainNavToggle {
  background-color: transparent;
  z-index: 50;
  padding: 0 15px;
}

.topNav .fa-search,
.topNav .fa-user {
  color: $white;
  font-size: 1.3rem;
  cursor: pointer;
}

#searchIcon {
  a {
    width: 30px;
    line-height: inherit;
    display: inline-block;
    @include breakpoint(small) {
      margin-right: 5px;
    }
  }
  &.active {
    .fa-search {
      @include cssX;
      background-color: $white;
      position: relative;
      top: -5px;
    }
  }
}

/*Menü-Icon animiert*/

.topNav.is-stuck .navtoggle {
  height: $topNavHeightSticky;
}

.navtoggle {
  display: block;
  float: right;
  width: 30px;
  height: $topNavHeight;
  text-decoration: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  > i {
    position: absolute;
    top: 49%;
    display: block;
    width: 100%;
    height: 3px;
    background-color: $white;
    border-radius: 0;
    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: 0;
      -webkit-transition: transform 0.4s;
      transition: transform 0.4s;
    }
    &:before {
      -webkit-transform: translateY(-0.5rem);
      transform: translateY(-0.5rem);
    }
    &:after {
      -webkit-transform: translateY(0.5rem);
      transform: translateY(0.5rem);
    }
  }
  &.open {
    -webkit-transform: rotate(45deg) translateY(3px) translateX(-3px);
    transform: rotate(45deg) translateY(3px) translateX(-3px);
    > i {
      &:after,
      &:before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transition: all 0.4s cubic-bezier(0.68, 0, 0.27, 1);
        transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1);
      }
    }
  }
}

// Searchbox-Block:
#searchBox {
  display: none;
  background-color: $very-light-bg;
  color: #000;
  width: 100%;
  box-shadow: 0 3px 3px 0 rgba(17, 17, 17, 0.3);
  margin-top: 0;
  padding-top: 0.25rem;
  top: $topNavHeight;
  position: absolute;
  z-index: 2; // die mindesthöhe auf der nächstunteren .row, ansonsten klappt der slideDown-Effekt nicht
  > .row {
    min-height: 80px;
  }
  #searchContainer {
    position: relative;
    height: 60px;
    overflow: hidden; // margin-bottom: 0.3125rem;
  }
  #suchfeld {
    position: relative;
    top: 20px;
    height: 39px;
    border-radius: 3px;
    width: 100%;
    padding-right: 2.8rem;
    margin: 0;
  }
  .submitIcon {
    position: absolute;
    top: 20px;
    right: 0;
    border-radius: 0 3px 3px 0;
    z-index: 1;
    color: $white;
    padding: 0;
    margin: 0;
    height: 39px;
    width: 39px;
    background-color: $gold-dark;
    transition: background-color 0.2s;
    &:hover {
      background-color: darken($gold-dark, 10%);
    }
  }
}

@include breakpoint(medium) {
  #searchBox {
    position: fixed;
    top: 55px;
    > .row {
      min-height: 90px;
    }
    #searchContainer {
      height: 70px;
    }
    #suchfeld {
      height: 50px;
      font-size: 1.5rem;
    }
    .submitIcon {
      top: 20px;
      height: 50px;
      width: 50px;
      font-size: 1.5rem;
    }
  }
}

/* Suchvorschläge */

#search_suggest {
  @include clearfix;
}

#suggLayer {
  margin-bottom: 0px; // TODO: Highlight wieder einbauen?
  // span.highlight {
  //   font-weight: bold;
  //   color: inherit;
  // }
  .suggItem,
  .suggItemHead {
    clear: both;
    float: left;
    cursor: pointer;
    width: 100%;
    color: $black;
    line-height: 1em;
  }
  .suggItemHead {
    display: none;
  }
  .suggItem {
    padding: 0.625rem 0.5rem;
    border-bottom: 1px solid $medium-gray;
    @include breakpoint(large) {
      padding: 0.8125rem 0.75rem;
    }
    &:hover {
      background: $light-gray;
      .suggCat,
      .suggName {
        color: $black;
      }
    } // TODO: Wofür ist diese Klasse? (wird über JS gesetzt). Notwendig?
    &.active {
      background: $light-gray;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .suggName {
    float: left;
    width: 90%; // TODO: Was ist das? Gibt's das noch?
    // &.help {
    //   font-family: NettoWeb-Bold,Verdana,Helvetica,Arial,sans-serif;
    //   font-weight: 400;
    //   font-size: 15px;
    //   color: #000;
    //   span {
    //     background: #4c9ed9;
    //     color: $white;
    //     padding: 1px 5px;
    //   }
    // }
    @include breakpoint(medium) {
      font-size: 1.125rem;
    }
  }
  .suggCat {
    float: right;
    width: 10%;
    text-align: right;
    &:after {
      content: "\f062";
      font-family: "FontAwesome";
      font-size: 1.25rem;
      float: right;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      color: $icon-color;
      @include breakpoint(medium) {
        font-size: 1.5rem;
      }
    }
  }
}

// MainNav (Ausgeklapptes Menü)
.topNav.is-stuck ~ #mainNav {
  position: fixed;
  top: $topNavHeightSticky;
}

#mainNav {
  display: none;
  position: absolute;
  top: $topNavHeight;
  left: 0;
  width: 100%;
  background: $very-light-bg;
  z-index: 50;
  padding-top: 1rem;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 50;
    position: relative;
    margin: 0;
  }
  .contact-icons {
    text-align: center;
    padding: 0.5rem;
    li {
      display: inline-block;
      a {
        color: $icon-color;
        padding: 0.5rem;
        font-size: 1.125rem;
        min-height: none;
        font-family: $header-font-family;
        cursor: pointer;
        display: block;
        i {
          font-size: 115%;
        }
      }
    }
  }
}

#mainNav a {
  padding: 12px 20px;
  min-height: 40px;
  color: #000;
  font-size: 1rem;
  line-height: 1;
  display: block;
  text-decoration: none;
  &.deepLink,
  &.desktop,
  &.mainLink {
    border-bottom: 1px solid #e1e1e1;
    &:after {
      content: "\f107";
      font-family: "FontAwesome";
      font-size: 1.5em;
      float: right;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      text-transform: none;
      color: $icon-color;
    }
    &.active {
      color: $primary-color;
    }
    &.active:after {
      content: "\f106";
      color: $primary-color;
    }
    &.deepLink:after {
      content: "";
    }
    &.desktop:after {
      content: "\f108";
      font-size: 1rem;
    } // &.infos {
    //   background-color: #aaa;
    //   color: $white;
    //   &:after {
    //     color: currentColor;
    //   }
    // }
    &.kontakt {
      background-color: $gold-light;
    }
    &.kontakt:after {
      content: "\f1d8";
      font-size: 1.25rem;
      padding-right: 2px;
      line-height: 0.75em;
    }
  }
}

/*Zweite Menü-Ebene*/

#mainNav ul ul {
  border-top: none;
  &.flyOut {
    display: none;
    li {
      line-height: 42px;
      a {
        display: block;
        padding-left: 40px;
        color: #444;
        font-size: 1rem;
        &.active {
          color: $primary-color;
        }
      }
    }
  }
}

/*dritte Menü-Ebene*/

#mainNav ul ul ul {
  border-top: none;
  &.subflyOut {
    display: none;
    li {
      line-height: 42px;
      border-bottom: 1px solid #e1e1e1;
      a {
        display: block;
        color: #444;
        font-size: 1rem;
        padding-left: 50px;
        text-transform: none;
        &:before {
          content: "\f105";
          font-family: "FontAwesome";
          font-size: 1.2em;
          padding-right: 5px;
          color: $icon-color;
        }
      }
    }
  }
  li:last-child {
    border-bottom: none;
  }
  li:first-child {
    // border-top: 1px solid #E1E1E1;
  }
}

/* Overlay */

#nav-overlay {
  @include coverer;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: none;
  /*default = none*/
}

#all-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.9);
  z-index: 9000;
  display: block;
  height: 100%;
}

// Ab hier MainNav-Desktop-Styles - CSS-Columns teilen die Nav-Elemente auf, die immer sichtbar sind (kein toggle mehr)
// ------------------------------------------------------------------------------------------------
@include breakpoint(medium) {
  #mainNav {
    padding-bottom: 2rem;
    a {
      padding: 5px 10px 5px 20px;
      min-height: 30px;
      font-size: 0.875rem;
    }
    .mainLink {
      font-size: 1.25rem;
      margin-top: 0.5rem;
      padding-left: 0.25rem;
      //cursor: default;  Toggle-pfeile ausstellen
      &:not(.deepLink):after {
        display: none;
      }
    }
    ul {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }
    // Flaggen auf Desktop neben den Ländern
    // TODO: jetzt deaktiviert, demnächst evtl löschen
    .land {
      // padding-left: 35px;
      // position: relative;
      // &:before {
      //     content: "";
      //     width: 22px;
      //     height: 15px;
      //     display: inline-block;
      //     background: url('/assets/gfx/sprite.png') no-repeat top left;
      //     position: absolute;
      //     left: 5px;
      //     top: 7px;
      // }
      // &.spanien:before {
      //     background-position: 0 -38px;
      // }
      // &.italien:before {
      //     background-position: -22px -38px;
      // }
      // &.bulgarien:before {
      //     background-position: -44px -38px;
      // }
      // &.kroatien:before {
      //     background-position: -66px -38px;
      // }
      // &.mexiko:before {
      //     background-position: -88px -38px;
      // }
    }
    // Zweite Ebene immer sichtbar auf desktop
    ul ul.flyOut {
      display: block;
      padding: 0;
      li {
        border-bottom: none;
        a {
          font-size: 1rem;
          padding-left: 1.25rem;
          line-height: 1.5;
          transition: color 0.2s;
          &:hover {
            color: $black;
            transition: color 0.2s;
          }
        }
      } // Dritte Ebene ist bei Abireisen nicht vorhanden
      // .subflyOut {
      //   display: block;
      //   li a {
      //     font-size: 0.875rem;
      //     padding-left: 20px;
      //   }
      // }
    } // Info-Elemente absolute, außerhalb der Column
    .nav-info-block {
      position: absolute;
      width: 100%;
      bottom: -3.5rem;
      left: 0;
      text-align: center;
    }
    // .contact-icons {
    //     position: absolute;
    //     bottom: -4rem;
    //     width: 100%;
    //     border-top: none;
    //     li a {
    //         font-size: 2rem;
    //         margin-right: 2rem;
    //     }
    // }
    // Split-Klasse (CSS-Columns) für Desktop-Ansicht
    .split {
      -webkit-columns: 3 200px;
      -moz-columns: 3 200px;
      columns: 3 200px;
    }

    .split > li {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid; // "inline-block": notwendig für Firefox, der sonst die <li>'s umbrechen lässen: http://stackoverflow.com/questions/7785374/how-to-prevent-column-break-within-an-element/7785711#7785711
      display: inline-block;
      width: 100%; // Außerdem: margin-bottom statt top, besser für automatische Umbrüche
      margin-bottom: 1rem;
      > .mainLink {
        margin-top: 0;
      }
    }
    .split .allow-break {
      -webkit-column-break-inside: auto;
      page-break-inside: auto;
      break-inside: auto;
    }
  }
}

// Kataloge + Partner
#mainNav .nav-aside {
  text-align: center;
  @include breakpoint(medium only) {
    padding-top: 2.5rem;
  }
  a {
    padding: 0;
  }
  span {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 1rem;
  }
  img {
    margin-bottom: 0.25rem;
    padding-top: 0.5rem;
  }
  .partner {
    max-width: 140px;
    margin: 0.5rem auto 0;
    text-align: left;
    position: relative;
    img {
      width: 35px;
      padding-top: 0.625rem;
      margin-left: -0.5rem;
      height: auto;
      float: left;
    }
    ul:before {
      content: "";
      position: absolute;
      background-image: url("/assets/gfx/sprite.png");
      background-position: -405px 0;
      width: 72px;
      height: 80px;
      transform: scale(0.5);
      left: -37px;
      top: -17px;
    }
    a {
      text-transform: none;
      min-height: 1.5rem;
      white-space: nowrap;
      padding-left: 0.5rem;
      margin-right: -0.5rem;
      font-size: 0.8125rem;
      color: #444;
      transition: color 0.2s;
      &:hover {
        color: $black;
        transition: color 0.2s;
      }
    }
  }
}
