// -----------------------------------------------------------------------------
// CALCULATOR
// -----------------------------------------------------------------------------



body.loading .calculator {
    position: relative;
    overflow: hidden;
    height: 500px;
    border: 1px solid #eee;

    &::after {
        content: "";
        @include coverer;
        background: rgba(255,255,255,0.97);
        z-index: 4;
    }
    &::before {
        content: "";
        @include absolute-center;
        z-index: 5;
        display: block;
        width: 35px;                
        height: 35px;
        top: 30%;
        left: 50%;
        margin-left: -15px;                
        border: 5px solid transparentize($brand, 0.5);
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: zus-spin 1s infinite cubic-bezier(0.25, 0.1, 0.25, 1);
    }
}

.calculator {
    background-color: transparent;
    float: none;
    border-radius: 2px;
    font-size: 1.125rem;
    padding: 0.125rem 0 1rem;
    margin: 0.5rem auto;
    max-width: 400px;
    font-weight: 400;
    text-align: center;

    @include breakpoint(large) {
        margin-top: -2rem;
    }

    [type="text"],
    select {
        border: none;
        border-bottom: 1px solid $light-gray;
        text-align: left;
        min-height: 3.125rem;
        line-height: 3.125rem;
        box-shadow: none !important;
        margin: 0 auto;
        display: block;
        padding: 0 2.5rem;
        border-radius: 0;
        padding-right: 3rem;
        cursor: pointer;
        color: $gold-dark;

        &:hover {
            background-color: #fafafa;
            border-color: $light-gray;
        }
        
        &:focus,
        &:active {
            border-color: inherit;
        }

        &:last-child {
            border-bottom: none;
        }
        
        // Selectbox statt Datepicker wenn wenig Termine
        &#cboanreisedatum {
            border-bottom: 1px solid $light-gray;
        }
    }
    
    // Angular-Datepicker (erzeugt input in einem <datepicker>-element)
    datepicker > input {
        @include background-triangle($dark-gray);
        background-size: 9px 6px;
        background-position: right -1.25rem center;
        background-origin: content-box;
        background-repeat: no-repeat;
    }

    // außerdem hat der erzeugte Datepicker-Element ein "float:left", das in Chrome77
    // dazu führt, dass das Element eine Höhe von 0 hat. Das hiermit korrigieren:
    datepicker {
        float: none !important;
        display: block !important;
    } 

    [readonly] {
        background-color: $white;
    }

    
    &__middle {
        box-shadow: 0 7px 8px rgba(0, 0, 0, 0.1);
        background: $white;
        position: relative;
        border-bottom: 1px solid $light-gray;
    }

    &__bottom {
        position: relative;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        background: $white;
        padding: 0.5rem;

        // Streichpreis
        del {
            color: $brand;
        }

        
        .button {
            font-size: 1rem;
            width: 200px;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }
    }

    &__price {
        margin: 0.5rem 0;
        font-weight: bold;
        font-family: $header-font-family;
        font-size: 2rem;
    }


    label {
        font-weight: 400;
    }
}

.addons-heading {
    background: #f7f7f7;
    text-align: center;
    padding: 0.5rem 0.25rem;
    font-size: 0.75rem;
    border-bottom: 1px dashed #ccc;
    color: #555;
}

.addons-list {
    margin: 0;
    padding: 0;
    @include clearfix;
    list-style: none;
    position: relative;
    display: flex;

    li {
        flex: 1 1 auto;
        // Drittel als Basis, wenn es weniger sind füllen die Label den Platz
        //  dank flex-grow (also werden 50/50 z.b.)
        width: calc(100% / 3);

        label {
            font-size: 0.9375rem;
            background: #f7f7f7;
            position: relative;
            width: 100%;
            height: 105px;
            border-right: 1px dashed #ccc;
            text-transform: uppercase;
            padding: 0.75rem;
            margin: 0;
            line-height: 1.1;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: -1px;
            transition: margin 0.35s ease-in;

            img {
                width: 30px;
                height: 30px;
                display: block;
                margin: 0 auto 0.5rem;
            }

            &:hover {
                background-color: #fafafa;
            }

            &:after {
                content: "+";
                display: block;
                font-size: 0;
                position: absolute;
                top: 70px;
                left: 50%;
                transform: translateX(-50%);
                height: 1.5rem;
                width: 1.5rem;
                border-radius: 50%;
                background: transparent;
                border: 2px solid #aaa;
                box-shadow: inset 0 0 0 10px #f7f7f7;
                // transition: all 0.15s ease-in-out;
            }

            small {
                text-transform: none;
                display: block;
                margin-top: 0.25rem;
                font-size: 0.75rem;
            }
        }
    }

    input {
        @include element-invisible;    
    }

    :checked + label {
        color: $gold-dark;

        &:after {
            font-size: 1.5rem;
            line-height: 1.125rem;
            border-color: $gold-dark;
            box-shadow: inset 0 0 0 0 #f7f7f7;
        }

        &:before {
            content: "";
            position: absolute;
        }
    }

    li:last-child label {
        border-right: none;
    }

    // Paket-infos
    :checked + label {
        border-bottom: none;
        background: #fff;

        &:active,
        &:hover {
            background: #fff;
        }

        // wenn danach noch ein element kommt, ist es das <div class="package-info">
        //  das ist absolut pos., daher PLatz schaffen mit Margin 
        &:not(:last-child) {
            margin-bottom: 4.5rem;
            transition: margin 0.15s ease-out;
        }
    }

    .package-info {
        visibility: hidden;
        overflow-x: auto;
        opacity: 0;
        position: absolute;
        background: #fff;
        text-align: left;
        padding: 0.75rem 1rem;
        height: 4.5rem;
        bottom: 0;
        left: 0;
        width: 100%;

        p {
            @include vertical-center;
            margin-bottom: 0;
            line-height: 1.3;
            font-size: 0.75rem;
            @include breakpoint(large) {
                font-size: 0.825rem;
            }
        }
    }

    // Nur sichtbar wenn Paket gewählt
    :checked ~ .package-info {
        visibility: visible;
        opacity: 1;
        transition: all 0.4s cubic-bezier(0.87, 0.09499, 0.94, 0.45499);
    }

}

// 3er-Switch (Bus, Flug, Selbstanreise)
$checked-color: $gold-dark;

.switch-wrap {
    width: auto;
    position: relative;
    font-size: 0;
    display: flex;

    input {
        position: absolute;
        left: -999999px;
    }

    label {
        font-size: 2rem;
        display: inline-block;
        color: #444;
        font-weight: 400;
        flex-grow: 1;
        margin: 0;
        // width: calc(100% / 3);
        width: 100%;
        z-index: 3;
        margin: 0;
        line-height: 1.2;
        box-shadow: inset 0 -6px 4px -4px rgba(0, 0, 0, 0.1);
        border-right: 1px dashed $medium-gray;
        transition: color 0.3s;
        // Beschriftung unter dem Icon
        &::after {
            content: attr(data-label);
            visibility: hidden;
            font-size: 0.75rem;
            font-family: $body-font-family, sans-serif;
            display: block;
            text-transform: uppercase;
            color: #444;
        }

        &:last-of-type {
            border-right: none;
        }

        svg {
            margin: 1rem 0 0;
            height: 3rem;
            width: 100%;
        }
    }

    input:checked + label {
        transition: color 0.3s;
        background-color: $white;
        color: $checked-color;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);

        &:after {
            visibility: visible;
        }

        svg .st0 {
            stroke: $gold-dark;
        }
    }


    // SVG-Styles generell
    .st0 {
        fill: none;
        stroke: #000000;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
    }
}

// Hinweis, wenn reise ausgebucht ist
.calculator__booked-out {
    background: rgba(255,255,255,1);
    text-align: center;
    padding: 3rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-weight: 400;
    
    span{
      display: block;
      color: #c65f9b;
      font-size: 2.5rem;
      font-weight: normal;
   }
    @include breakpoint(large) {
        //margin bottom, damit das mit die USPs weiter link floaten
        margin-bottom: 10rem;
    }
}

.calculator__gruppenanfrage {
    // ins padding von calculator__bottom reinziehen
    width: calc(100% + 1rem);
    margin: 0 0 -0.5rem -0.5rem;
    position: relative;
    text-transform: uppercase;
    color: $black;
    font-size: rem-calc(13);
    text-align: left;
    font-family: $header-font-family;
    padding: 1rem 0.5rem 0.5rem 3rem;
    font-weight: 400;
    letter-spacing: 0.05em;
    @include breakpoint(medium) {
        font-size: rem-calc(16);
    }

    a {
        color: inherit;
    }

    .gruppenanfrage-flag {
        position: absolute;
        left: 0.75rem;
        bottom: 0;

        // Die Schräge hier mit Shadow
        &::after {
            filter: drop-shadow(1px 2px 3px rgba($black, 0.2))
        }
    }
}

// Hinweis am Calculator ("30 Tage kostenlos")
.calculator__bubble {
    background: linear-gradient(45deg, $gold-dark, $gold);
    width: 90%;
    max-width: 280px;
    margin: 1rem auto 0;
    text-align: center;
    padding: 1rem 0.75rem;
    color: $white;
    font-weight: 400;
    font-size: rem-calc(15);
    line-height: 1.4;
    border-radius: 5px;
    position: relative;
    opacity: 0;
    font-family: $header-font-family;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    // box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

    // &.lazyload, 
    // &.lazyloading {
    //     opacity: 0;
    // }

    &.lazyloaded {
        animation: bounceIn 0.75s 0.25s forwards;
    }
    
    &::after {
        content: "";
        position: absolute;
        top: -0.5rem;
        left: 50%;
        margin-left: -0.25rem;
        @include css-triangle(0.625rem, mix($gold-dark, $gold, 50%), up);
    }

    a {
        color: inherit;
        font-weight: bold;
        text-decoration: underline;

        &:focus,
        &:hover {
            color: inherit;            
        }
    }
}

