/* PAGES - Häufig wiederkehrende Seitenelemente*/
/* Im Zweifel CSS einfach hier rein schreiben*/
/* -------------------------------------------*/

/*Nach externen Link entsprechendes Icon*/
a.external:after {
  font-family: "FontAwesome";
  content: "\f08e";
  font-size: 0.65em;
  vertical-align: text-top;
  padding-left: 0.25em;
}

.pdf:after {
  content: "\f1c1";
  font-family: "FontAwesome";
  font-size: 1rem;
  color: red;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

/* Bausteine*/
.section-heading {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  @include breakpoint(medium) {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
}

.margin-top {
  margin-top: 0.75rem;
  @include breakpoint(medium) {
    margin-top: 1.5rem;
  }
}

.margin-bottom {
  margin-bottom: 0.75rem;
  @include breakpoint(medium) {
    margin-bottom: 1.5rem;
  }
}

.clearfix {
  @include clearfix;
}

.no-shadow {
  box-shadow: none;
}

@include breakpoint(large) {
  .split-2 {
    columns: 2;
    column-gap: 3em;
  }
  .split-3 {
    columns: 3;
    column-gap: 2em;
  }
}

.linklist {
  margin: 0 0 0.5em;
  list-style: none;
}

.padded li {
  padding-bottom: 0.25rem;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper embed,
.videoWrapper object,
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Alternative Klasse (testweise)
.video_contain {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
  }
}

iframe {
  border: none;
  margin: 0 auto;
}

.wide-intro {
  h1,
  h2,
  h3 {
    letter-spacing: 0.05em;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
  }
  h1 {
    margin-top: 1.75rem;
    font-weight: 300;
  }
  h2,
  .h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-family: $header-font-family;
    @include breakpoint(medium) {
      font-size: 1.75rem;
    }
  }
  h1 > span {
    font-size: 1.25rem;
    margin: 0.25rem;
    display: block;
    line-height: 1;
    color: $black;
    font-family: $header-font-family;
    @include breakpoint(medium) {
      font-size: 1.75rem;
    }
  }
}

/*hr*/.hr-gold {
  border-color: $gold-light;
  border-width: 2px;
}

.product-desc {
  h1 {
    @include breakpoint(medium only) {
      font-size: 3rem;
    }
  }
  .h2 {
    font-size: 1.5rem;
  }
}

.fullwidth-image {
  width: 100%;
  background-size: cover;
  height: 300px;
  color: $white;
  font-size: 1.5rem;
  background-position: center;
  position: relative;
  margin-bottom: 1rem;
  @include breakpoint(medium) {
    height: 420px;
  }
  .fullwidth-image-title {
    @include absolute-center;
    text-align: center;
    background-color: transparentize($anthrazit, 0.15);
    font-family: $header-font-family;
    letter-spacing: 0.0625em;
    line-height: 1.2;
    white-space: normal;
    text-transform: uppercase;
    max-width: 90%;
    font-size: 1.5rem;
    padding: 0.25rem 1.5rem;
    @include breakpoint(medium) {
      font-size: 2rem;
      white-space: nowrap;
    }
    @include breakpoint(large) {
      font-size: 3rem;
      padding: 0.5rem 2.5rem;
    }
  }
}


/* Intro-Bild*/

.parallax-img {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  z-index: -2;
  position: relative;
}

.intro-img {
  background-position: top center;
  background-size: cover;
  position: relative;
  height: 350px;
  margin-bottom: -1px;
  overflow: hidden;
  @include breakpoint(medium) {
    height: 540px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.15);
  }

  // Hintergrundbild kann auch in extra Container platziert werden, damit der
  // Lazyload Blur-Effekt nicht die drüberliegenden Elemente betrifft
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.intro-img.service {
  height: 300px;
  @include breakpoint(medium) {
    height: 420px;
  }
}

.intro-img-text {
  z-index: 1;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.15);
  @include absolute-center;
  width: 90%;
  text-align: center;
  font-size: 1.75rem;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 400;
  font-family: $header-font-family;
  letter-spacing: 0.05em;
  @include breakpoint(medium) {
    font-size: 2.75rem;
  }

  small {
    display: block;
    font-size: 65%;
  }
}

// CTA-Button direkt nach Hero, überlappt nach oben
.intro-cta {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: center;
  position: relative;
  z-index: 1;

  .button {
    margin: 0;
    box-shadow: 0 1px 8px rgba(0,0,0,0.18),
                0 2px 20px rgba(0,0,0,0.10);
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
    transform: translateY(-50%);
    margin-bottom: -1.5em;
    
    @include breakpoint(medium) {
      padding-left: 1.25em;
      padding-right: 1.25em;
      font-size: 1.25rem;
    }
    @include breakpoint(large) {
      font-size: 1.325rem;
    }
  }
}

// ACHTUNG: Im Gegensatz zu ruf ist die "sale-notice" hier die runde Box
// Das "sale-tape" hier ist das bekannte Band
.sale-notice {
  background: $brand;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem;
  font-weight: 400;
  display: inline-block;
  color: $white;
  width: 85%;
  max-width: 500px;
  position: relative;
  line-height: 1.3;
  &::before {
    @include css-triangle (25px, $brand, up);
    top: -24px;
    right: 50%;
    margin-right: -25px;
    position: absolute;
  }
  p {
    margin: 0;
  }
}

.sale-tape {
  font-weight: 400;
  text-align: center;
  font-size: 0.925rem;
  padding: 0.325em 0;
  color: $white;
  overflow: hidden;
  background-color: $brand;
  a {
    color: inherit;
  }
  @include breakpoint(medium) {
    font-size: 1rem;
  }
  &.large {
    font-size: 1rem;
    font-family: $header-font-family;
    text-transform: uppercase;
    letter-spacing: 1px;
    @include breakpoint(medium) {
      font-size: 1.5rem;
    }
    small {
      font-size: 65%;
      text-transform: none;
    }
  }
}

// Countdown: Tage, Stunden etc unter den Zahlen
.jq_saleCountdown {
  white-space: nowrap;
  .countdown-section {
    position: relative;
    padding-bottom: 0.5em;
    padding-top: 0.325em;
    top: -0.125rem;
    display: inline-block;
    margin: 0 0.125em;
    min-width: 1.5em;
    text-align: center;
  }
  .countdown-period {
    position: absolute;
    bottom: 2px;
    font-size: 45%;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }
}

.text-countdown {
  padding: 1rem 0.75rem 1rem;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: 1.1;
  font-family: $header-font-family;
  font-size: 1rem;
  @include breakpoint(medium) {
    font-size: 1.5rem;
  }
  
  .jq_saleCountdown {
    color: $brand;
    font-size: 150%;
    display: block;
    @include breakpoint(medium) {
      display: inline-block;
      vertical-align: middle;
      font-size: 125%;
    }
  }
}

/* Ort-Seite*/

.intro-side {
  h3 {
    margin-bottom: 0;
    letter-spacing: 3px;
  }
  ul {
    list-style: none;
  }
  li {
    padding-bottom: 0.25rem;
  }
  p {
    margin-bottom: 0.25rem;
  }
}

// Produkt-Seite
.product-intro {
  margin-top: 1rem;
}

.product-location {
  text-transform: uppercase;
  font-family: $header-font-family;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
  @include breakpoint(medium) {
    font-size: 1.5rem;
  }
}

.product-title {
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  font-family: $header-font-family;
  font-size: 2rem;
  @include breakpoint(medium) {
    font-size: 2.5rem;
  }
}

/* USP-List*/

.usp-list {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  text-align: left;
  list-style: none;
  padding: 1.5rem 0 0.5rem;
  color: $anthrazit;
  li {
    margin-bottom: 1.5rem;
    padding-left: 4em;
    position: relative;
    width: 100%;
    @include breakpoint(large) {
      width: 47%;
      &:nth-child(odd) {
        float: left;
        clear: left;
      }
      &:nth-child(even) {
        float: right;
        clear: right;
      }
    }

    // Überschriften
    span {
      font-family: $header-font-family;
      letter-spacing: 0.05em;
      font-weight: 400;
      font-size: 120%;
    }
  }

  .item_BUCHUNGSZEITRAUM:before {
    content: "";
    background-image: url("/assets/gfx/sprite.png");
    background-position: 0 0;
    position: absolute;
    left: 10px;
    height: 38px;
    width: 40px;
  }

  .item_LAGE:before {
    content: "";
    background-image: url("/assets/gfx/sprite.png");
    background-position: -40px 0;
    position: absolute;
    left: 10px;
    height: 38px;
    width: 40px;
  }

  .item_WOHNEN:before {
    content: "";
    background-image: url("/assets/gfx/sprite.png");
    background-position: -80px 0;
    position: absolute;
    left: 10px;
    height: 37px;
    width: 40px;
  }

  .item_CHILL-FAKTOR:before {
    content: "";
    background-image: url("/assets/gfx/sprite.png");
    background-position: -120px 0;
    position: absolute;
    left: 10px;
    height: 38px;
    width: 40px;
  }

  .item_SUPREMIUM:before,
  .item_SUPREMIUM-PAKET:before {
    content: "";
    background-image: url("/assets/gfx/supremium-krone.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    height: 38px;
    width: 40px;
  }

  .item_SUPREMIUM,
  .item_SUPREMIUM-PAKET {
    background-color: $very-light-bg;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  // Neue USPs, als svgs statt png-Sprite
  .item_HIGHLIGHTS::before,
  .item_BEREITS::before,
  .item_BEREITS-INKLUSIVE::before {
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    background-image: url('/assets/gfx/icons/highlights-icon.svg');
    height: 40px;
    width: 40px;
    left: 8px;
  }

  .item_KUNDENMEINUNG::before {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url('/assets/gfx/icons/daumen-icon.svg');
      position: absolute;
      left: 10px;
      height: 40px;
      width: 40px;
  }

  .item_PARTY::before {
      content: "";
      background-size: contain;
      background-image: url("/assets/gfx/icons/party-icon.svg");
      background-repeat: no-repeat;
      position: absolute;
      left: 15px;
      height: 38px;
      width: 40px;
  }

  .item_PARTYCLUB::before {
      content: "";
      background-size: contain;
      background-position: center;
      background-image: url('/assets/gfx/partyclub.svg');
      background-repeat: no-repeat;
      position: absolute;
      height: 40px;
      left: 11px;
      width: 40px;
  }

  // Sonderfall Gruppenanfrage: Icon als <div>, inline
  .item_GRUPPENANFRAGE {
    
    .gruppenanfrage-flag {
      position: absolute;
      top: 0.5rem; 
      left: 1.25rem;
    }
  }
}

// Abstract
.abstract ul {
  list-style: none;
  margin: 0;
  padding: 0.25em 0;
  line-height: 1.4;
  @include breakpoint(medium) {
    line-height: 1.5;
  }
  
  li {
    font-size: 1.0625rem;
    font-weight: 400;
    display: inline;
    @include breakpoint(medium) {
      font-size: 1.125rem;
    }

    &:after {
      content: "+";
      margin: 0 0.5em;
      display: inline-block;
    }

    &:last-of-type::after {
      content: "";
    }

    &:first-of-type::before {
      content: "//";
      padding-right: 0.25em;
      font-size: 1.25em;
      line-height: 1;
    }
  }
}

// /* Modal-Fenster*/
// .modal-on-mobile {
//   margin-bottom: 1rem;

//   a:last-of-type {
//     position: relative;
//     display: block;
//     top: -1rem;
//     margin-top: -5rem;
//     padding-top: 4rem;
//     width: 100%;
//     left: 0;
//     text-align: center;
//     outline: none;
//     background: -webkit-linear-gradient(
//       top,
//       rgba(255, 255, 255, 0),
//       $white 70%
//     );
//     background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white 70%);
//   }
//   .reveal {
//     background: $white;
//     box-shadow: 0 2px 40px rgba(0, 0, 0, 0.25);
//   }
// }

.reveal-close {
  position: relative;
  width: auto;
  height: 40px;
  margin: -1rem -1rem 1rem;
  background: $anthrazit;
  .close-button {
    color: $gold-light;
    font-size: 3rem;
    top: 0;
    right: 1rem;
    line-height: 35px;
    small {
      font-size: 0.75rem;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }
}

.reveal {
  border: none;
}

#booking_reminder_anchor {
  height: 0;
}

#booking_reminder {
  bottom: 1rem;
  left: 0;
  right: 0;
  padding: 0 0.625rem;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  &.is-stuck {
    opacity: 1;
  }
  .button {
    width: 100%;
  }
}

/* Supremium-Block auf Produktseite*/

.supremium {
  text-align: center;
  background-color: $very-light-bg;
  padding: 3rem 1rem 1.5rem 1rem;
  margin-top: 80px;
  margin-bottom: 10px;
  position: relative;
  /* bei kleineren Viweports mehr platz wg. der Krone*/
  @include breakpoint(medium) {
    margin-top: 20px;
  }
  &:before {
    content: "";
    width: 106px;
    height: 107px;
    top: -60px; // Mittig positionieren und drehen
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-12deg) translateZ(0);
    transform-origin: 50% 51%;
    outline: 1px solid transparent; // backface-visibility: visible;
    background: url("/assets/gfx/supremium-krone.png") top center no-repeat;
    background-size: 90px;
  }
  .supremium-header {
    font-family: $header-font-family;
    font-weight: 300;
    color: $gold-dark;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 1.6;
    margin-bottom: 0;
    letter-spacing: 3px;
    @include breakpoint(large) {
      font-size: 3rem;
      line-height: 1;
    }
  }
  .supremium-subheader {
    font-family: $header-font-family;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 1rem;
    @include breakpoint(large) {
      font-size: 1.25rem;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    li {
      display: block;
      margin: 0.375rem auto;
    }
  }
}

/* Supremium-Seite */

.supremium-page-intro {
  text-align: center;
  padding-bottom: 1rem;
  img {
    margin-bottom: 1.5rem;
  }
  p {
    text-align: left;
  }
}

.supremium-page-list {
  list-style: none;
  > li {
    float: left;
    padding: 1rem;
    width: 100%;
    @include breakpoint(large) {
      @include auto-width(3);
    }

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      left: 50%;
      margin-left: -43px;
      width: 86px;
      height: 99px;
      background: url("/assets/gfx/abireisen-supremium-ziffern-sprite.png")
        no-repeat;
    }
    &:nth-of-type(1):before {
      background-position: 0 0;
    }
    &:nth-of-type(2):before {
      background-position: -86px 0;
    }
    &:nth-of-type(3):before {
      background-position: -172px 0;
    }
    ul {
      list-style: none;
      margin: 0 auto;
      li {
        padding-bottom: 0.3125rem;
      }
    }
    h2,
    h3,
    h4,
    p {
      text-align: center;
      @include breakpoint(large) {
        text-align: left;
      }
    }
  }
}

// Supremiu-LANDING-Seite
.supremium-landing-icon {
  display: block;
  margin: 0 auto;
  margin-top: -70px;
}

// "Elevator" - der nach-oben-Button
#elevator {
  position: fixed;
  top: 0.5rem;
  left: 50%;
  width: 110px;
  margin-left: -55px;
  height: 40px;
  line-height: 40px;
  font-size: 0.8rem;
  border-radius: 0px;
  background-color: $white;
  font-weight: 400;
  text-align: center;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  display: none;
  a {
    color: $black;
    height: 100%;
    width: 100%;
    display: block;
    cursor: pointer;
    &:hover,
    &:active {
      color: $black;
    }
    &:before {
      content: "\f0a6";
      padding-right: 5px;
      font-family: "FontAwesome";
    }
  }
  &.active {
    display: block;
  }
}

// Ladeoverlay
// body.dom-loading .loading-overlay,
body.loading-again {
  overflow: hidden;
}
body.loading-again .loading-overlay {
  display: block;
}

.loading-overlay {
  position: fixed;
  display: none;
  z-index: 2000;
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: wait;

  &::after {
    content: "";
    display: block;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -25px;
    border: 8px solid transparentize($gold, 0.3);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: zus-spin 1s infinite cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}


.gruppenanfrage-flag {
  width: 1.75rem;
  height: 1.75rem;
  display: inline-block;
  background: $signal;
  position: relative;
  background-clip: padding-box;
  border-bottom: 1px dashed $signal;

  &::before {
    content: "+";
    color: $black;
    font-weight: 400;
    font-size: 1.125rem;
    font-family: $body-font-family;
    @include absolute-center;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: calc(-1.25rem - 1px);
    z-index: -1px;
    left: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 1.25rem 1.75rem 0 0;
    border-color: $signal transparent transparent transparent;
  }
}


// Accordion
.accordion.accordion--simple {
  background: transparent;

  .accordion-item {
    margin-bottom: 1rem;
  }

  .accordion-content {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;    
    background: transparent;
    border: none;
    color: $black;
  }

  .accordion-title {
    background: transparent;
    border: none;
    padding: 0.325rem 0.75rem;
    padding-right: 2.25rem;
    font-weight: bold;
    color: $body-font-color;
    font-size: 1rem;
  }
}

// Accordion-light: Luftigere Schrift
.accordion.accordion--light {
  background: transparent;

  .accordion-item {
    margin-bottom: 1rem;
  }

  .accordion-content {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;    
    background: transparent;
    border: none;
    color: $black;
  }

  .accordion-title {
    background: transparent;
    border: none;
    padding: 0.325rem 0.75rem;
    padding-right: 2.25rem;
    font-family: $header-font-family;
    letter-spacing: 0.05em;
    color: $body-font-color;
    font-size: 1.25rem;
  }
}

// "accordion--only-small" -> Auf größeren Viewports deaktiviert
.accordion.accordion--only-small {
  @include breakpoint(medium) {
    .accordion-content {
      display: block !important;
    }

    .accordion-title {
      pointer-events: none;

      &::before {
        content: none;
      }
    }
  }
}

// wiederkehrende "price"-Schaltfläche
.price {
    background-color: $brand;
    text-transform: none;
    color: $white;
    display: inline-block;
    padding: 0.325em 1em 0.5em 1em;
    font-weight: 400;
    line-height: 1.4;
    font-size: 1.125rem;
    font-family: $header-font-family;
    text-align: center;
    transition: background-color 0.2s;
    
    &:hover,
    &:focus {
      color: $white;
      background-color: darken($brand, 1.5%);
      transition: background-color 0.2s;
    }

    .old-price {
        font-size: 80%;
        color: $black;
        text-decoration: line-through;
    }
}


// Fairplay: Karten vor großem Bild
.fairplay {
  position: relative;
  padding: 4rem 1rem 1rem;

  &__img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: calc(100% - 5rem);
    background-size: cover;

    &::after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      // background: hsla(327, 100%, 5%, 0.3);
      // background: hsla(35, 95%, 9%, 0.22);
    }
  }

  &__title {
    position: relative;
    max-width: 1000px;
    margin: 1rem auto 3rem;
    text-align: center;
    font-family: $header-font-family;
    font-weight: 400;
    text-transform: none;
    // color: $white;
    color: $black;
    letter-spacing: 0;
    font-size: 2rem;
    @include breakpoint(medium) {
        font-size: 2.75rem;
        margin-bottom: 5rem;
    }
  }
}


/*ul*/.fairplay-cards {
    // oben extra margin, für überstehendes Bubble
    margin: 2rem 0 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
}

/*li*/.fairplay-card {
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column;
    max-width: 400px;
    position: relative;
    margin-bottom: 0.75rem;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06));
    color: $body-font-color;

    @include breakpoint(medium) {
        width: calc(50% - 2rem);
        margin: 0 1rem;
    }

    @include breakpoint(large) {
        width: calc(50% - 4rem);
        margin: 0 2rem;
    }

    a {
        color: inherit;
        flex: inherit;
        display: inherit;
        flex-flow: inherit;
        width: 100%;
        height: 100%;
    }

    &__top {
        width: 100%;
        position: relative;
        font-size: 1.5rem;
        text-align: center;
        background: $white;
        padding: 2rem 1rem 1.5rem;
        font-family: $header-font-family;
        position: relative;
        flex: 0 0 140px;
        font-size: 1.325rem;
        font-weight: 400;
        line-height: 1.2;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        @include breakpoint(medium) {
            flex-basis: 170px;
            font-size: 1.75rem;
        }


        em {
          font-size: 140%;
          font-style: normal;
          @include breakpoint(medium) {
            font-size: 160%;
          }
        }
    }

    &__badge {
        position: absolute;
        top: -1.25em;
        left: -0.75em;
        z-index: 1;
        font-family: $header-font-family;
        background: $anthrazit;
        color: $white;
        border-radius: 0.5rem;
        border: 3px solid $white;
        transform: rotate(-8deg);
        text-align: center;
        padding: 0.75em;
        display: inline-flex;
        font-weight: 500;
        font-size: 1rem;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        align-content: center;
        line-height: 1.1;
        @include breakpoint(medium) {
            font-size: 1.125rem;
        }
    }

    &__action {
        position: absolute;
        bottom: -1rem;
        left: 0;   
        width: 100%;
        
        .button {
            margin: 0;
            box-shadow: 0 1px 4px rgba(0,0,0,0.12),
            0 2px 8px rgba(0,0,0,0.06);
        }
    }

    &__bottom {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        background: $very-light-bg;
        padding: 1rem;
        padding-top: 2rem;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
}

// Neu: Container für CMS-Preis, optisch wie bei ruf (Magenta-Preis, Streichpreis dahinter)
.product-price {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: $brand;
  font-weight: 700;
  
  // Der Streichpreis wird vom CMS als erstes ausgegeben, hiermit nach hinten verschieben
  .old-price {
    font-weight: 400;
    order: 1;
    color: #333;
    margin-left: 0.25rem;
    text-decoration: line-through;
  }
}

// Special-Offer auf Produktseite
// Styles für "Best-Price-Wochen", kann für neue Aktionen angepasst werden
.special-offer {
  color: $brand;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  max-width: 400px;

  @include breakpoint(large) {
    margin-top: 0;
  }

  h4:first-of-type {
    border-top: 2px dashed currentColor;
    border-bottom: 2px dashed currentColor;
    font-weight: 400;
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    width: auto;
    transform: rotate(-1.5deg);
    @include breakpoint(medium) {
      font-size: 1.5rem;
    }
  }

  // normalerweise wird der Calculator mit einer nagativen margin weiter nach oben gezogen
  // Das hier deaktivieren
  + .calculator {
    margin-top: 0;
  }
}