// TIMELINE
// ---------------------------

#timeline {
  position: relative;
  padding: 1rem 0 2rem;
  &::before {
    /* this is the vertical line */
    content: "";
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: $light-bg;
  }
  @include breakpoint(large) {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 3em;
    &::before {
      left: 50%;
      margin-left: -2px;
    }
  }
  // .button-group .button {
  //   margin-bottom: 0.5rem;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
  .button {
    margin-bottom: 0.5rem;
    display: block;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}

.timeline-block {
  position: relative;
  margin: 2em 0;
  @include clearfix;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  @include breakpoint(large) {
    margin: 4em 0;
  }
}

.timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: $anthrazit;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.12),
    0 3px 0 4px rgba(0, 0, 0, 0.08);

  span {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    font-family: $header-font-family;
    @include absolute-center;
  }

  @include breakpoint(large) {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

.timeline-header {
  text-transform: uppercase;
  font-family: $header-font-family !important;
  letter-spacing: 1px;

  @include breakpoint(large) {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
  }
}

.timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 8px;
  padding: 1rem 1rem 0;
  @include clearfix;
  // box-shadow: 0 3px 0 rgba(51, 54, 59, 0.65);
  transition-timing-function: cubic-bezier(0.68, 0, 0.27, 1.2) !important;

  @include breakpoint(large) {
    margin-left: 0;
    padding: 2rem 1.5rem 0;
    width: 45%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    right: 100%;
    height: 0;
    width: 0;
    border: 12px solid transparent;
    border-right: 15px solid white;
    @include breakpoint(large) {
      top: 15px;
      left: 100%;
      border-color: transparent;
      border-left-color: white;
    }
  }

  h4 {
    font-family: $header-font-family;
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-bottom: 0;
    @include breakpoint(large) {
      font-size: 1.375rem;
    }
  }
}

.timeline-special {
  background: $gold-light;
  color: $black;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 1rem;
  padding: 1.5rem 1rem;
  @include breakpoint(large) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: 1.5rem;
    padding: 2rem 1.5rem;
  }

  .timeline-supremium {
    padding-left: 50px;
    line-height: 50px;
    vertical-align: middle;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 50px;
      width: 50px;
      background: url("/assets/gfx/supremium-krone.png") no-repeat;
      background-size: 40px;
    }
  }
}

.timeline-contact {
  text-align: center;
  margin: 0.75rem 0;
  a {
    display: inline-block;
    margin: 0 1.5rem 0 0;
  }
}

// Abwechselnd links und rechts floaten (nur auf Desktop)
@include breakpoint(large) {
  .timeline-block:nth-child(even) .timeline-content {
    float: right;

    &:before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: white;
    }
    
    .timeline-header {
      left: auto;
      right: 122%;
      text-align: right;
    }
  }
}
