// -----------------------------------------------------------------------------
// Overrides
// -----------------------------------------------------------------------------

.max600 {
  max-width: 600px !important;
  margin-left: auto;
  margin-right: auto;
}

.max800 {
  max-width: 800px !important;
  margin-left: auto;
  margin-right: auto;
}

.max1000 {
  max-width: 1000px !important;
  margin-left: auto;
  margin-right: auto;
}

.max1200 {
  max-width: 1200px !important;
  margin-left: auto;
  margin-right: auto;
}

.max1400 {
  max-width: 1400px !important;
  margin-left: auto;
  margin-right: auto;
}

.max1600 {
  max-width: 1600px !important;
  margin-left: auto;
  margin-right: auto;
}

// Hintergrundfarben
.white-bg {
  background: $white;
}

.light-bg {
  background: $light-bg;
}

.very-light-bg {
  background-color: $very-light-bg;
}

.dark-bg {
  background-color: $dark-bg;
}

.gold-bg {
  background-color: $gold-light;
}

.signal {
  background-color: $signal;
}

.gradient-1 {
  background-image: $gradient-1;
}

// Farben

.color, 
.color-1 {
  color: $gold !important;
}

.color-2 {
  color: $brand !important;
}

.color-white {
  color: $white !important;
}

.color-default {
  color: $body-font-color !important;
}



.bg-pos-btm {
  background-position: bottom center !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: none !important;
}

.fw700 {
  font-weight: 700 !important;
}

.nowrap {
  white-space: nowrap !important;
}
