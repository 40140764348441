// Lists - <li>-Strukturen, wie Ergebnisliste, Hotelliste etc.
// ----------------------------------------------------------
/* Hotel-liste */
/*ul*/
.results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

/*li*/
.result {
  color: $black;
  background-color: $white;
  margin-bottom: 1rem;
  padding: 0;
  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  box-shadow: 0 2px 30px rgba(0,0,0,0.05);
  // overflow: hidden;

  @include breakpoint(medium) {
    width: calc((100% / 2) - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;

    // <li> mit Intro-Text hier noch über ganze Breite, erst später in die Zeilen einreihen
    &--text {
      width: 100%;
    }
  }

  @include breakpoint(large) {
    width: calc((100% / 3) - 1rem);

    // bei <ul class="results results--large" maximal zweispaltig werden
    ul.results--large & {
      width: calc((100% / 2) - 1rem);
    }
  }

  &--special {
    background-color: $light-bg;
  }

  &--text {
    background: none;
    box-shadow: none;
    padding: 0.25rem 0;

    @include breakpoint(medium) {
      padding: 1rem;
    }
  }

  // <a>-Link um alles löst Flexbox auf, hier neu vergeben
  > a {
    display: flex;
    flex-flow: column;
  }

  // Bild
  &__picture {
    display: block;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    height: 0;
    // Für 360 x 200 Videos: 55% padding bottom
    padding-bottom: 55%;
    overflow: hidden;

    video {
      position: absolute;
      z-index: 0;
      width: 100%;
      min-height: 100%;
      opacity: 0;
      -webkit-transition: opacity 0.8s;
      transition: opacity 0.8s;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .result:hover & {
      video {
        opacity: 1;
      }
    }
  }

  // Label im Bild
  &__label {
    position: absolute;
    top: 1.5rem;
    left: 0;
    padding: 0.25em 0.5em;
    text-align: center;
    font-size: 0.925rem;
    font-weight: 700;
    background-color: $brand;
    color: $white;
  }

  // Icon im Bild, Sprung zur Map
  &__map-jump {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-image: url("/assets/gfx/sprite.png");
    background-repeat: no-repeat;
    background-position: -345px 0;
    width: 60px;
    height: 60px;
  }

  // Text unter dem Bild
  &__content {    
    padding: 1rem;
    height: 100%;
    color: $body-font-color;
    position: relative;
    font-size: 0.925rem;
    text-align: center;
    font-weight: 400;
    display: flex;
    flex-flow: column;

    &:hover,
    &:focus {
      color: $body-font-color ;
    }

    @include breakpoint(medium) {
        padding: 1rem 2rem 2rem;
    }

    // bei 'large' noch eine Mindesthöhe, die dreispaltigkeit ist wegen unterschiedlichen Teasertexten da sonst unregelmäßig hoch
    // (bei xlarge und medium geht's)
    @include breakpoint(large only) {
        min-height: 350px;
        
        // aber nicht bei zweispaltigen Teasern
        ul.results--large & {
          min-height: unset;
        }
    }


    h4 {
      text-align: center;
      font-size: 1.5rem;
      letter-spacing: 0.05em;
      font-weight: 400;
      text-transform: uppercase;
      @include breakpoint(medium) {
        //text-align: left;
        font-size: 1.75rem;
      }

      small {
        color: inherit;
        opacity: 0.75;
        display: block;
        line-height: 1.4;
        font-size: 75%;
        margin-bottom: 1rem;
      }
    }
  }

  &__price {
    text-align: center;
    margin-top: auto;    

    // .price-Element (Streichpreise etc) innerhalb Container
    .price {
      margin-top: 1rem;
    }
  }
}

// veraltet?
.hotel-icons {
  float: left;
  margin-bottom: 1.5rem;
  @include breakpoint(large) {
    /* wg. absolut pos. Hotel-Foto viel padding-left
    padding-left: 210px;*/
  }
}

.hotel-icon {
  display: inline-block;
  width: 70px;
  height: 85px;
  margin: 0 0.35rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 11px;
  line-height: 13px;
  color: #333;
  text-align: center;
  padding: 55px 5px 0;
  background-image: url("/assets/gfx/sprite.png");
  background-repeat: no-repeat;

  &.strand {
    background-position: 0 -53px;
  }

  &.wlan {
    background-position: -207px -53px;
  }

  &.pool {
    background-position: -138px -53px;
  }

  &.disco {
    background-position: -69px -53px;
  }

  &.misc {
    background-position: -276px -53px;
  }
}

// PRODUKTSEITE

// Preise auf Produkt-seite
.action-block-hotel {
  float: none;
  margin-top: 0.5rem;
}

.anfrage-button {
  font-size: 0.875rem;
  @include breakpoint(medium) {
    font-size: 1rem;
  }
}

.preis {
  margin-bottom: 0.5rem;

  strong {
    font-size: 3rem;
  }
  @include breakpoint(small only) {
    strong {
      font-size: 2rem;
    }
  }
}

.name {
  font-family: $header-font-family;
  font-weight: 400;
  line-height: 1.1;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  @include breakpoint(medium) {
    font-size: 2.5rem;
  }
}

// SUCHERGEBNISSE
// Suchergebnis-Liste (wiederverwendbar? Heisst jetzt neutral "block-list")
.block-list {
  list-style: none;
  text-align: center;
  padding: 2rem 0.5rem 0.5rem;

  li {
    position: relative;
    margin-bottom: 1rem;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    // Netter Hover-effekt, http://tobiasahlin.com/blog/how-to-animate-box-shadow/
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      z-index: -1;
      top: 0;
      left: 10%;
      width: 80%;
      height: 100%;
      // anfangs keinen Schatten zeigen (sieht seltsam aus, wenn noch keine Bilder da sind)
      animation: fade-in 1s forwards;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
      opacity: 0;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      // -webkit-transform: scale(1.05);
      // transform: scale(1.05);
      z-index: 2;
    }

    &:hover::after {
      opacity: 1;
    }
  }

  img {
    width: 100%;
  }

  .intrinsic {
    background-color: $white;

    i {
      color: $anthrazit;
      @include absolute-center;
      font-size: 4rem;
      @include breakpoint(medium) {
        font-size: 5rem;
      }
      @include breakpoint(large) {
        font-size: 6rem;
      }
      @include breakpoint(xlarge) {
        font-size: 7rem;
      }
    }
  }

  .block-content {
    position: relative;
    background-color: $white;
    color: $black;
    padding: 0.5rem 0.3125rem 2.8rem;
    min-height: 180px;

    p {
      font-size: 0.875rem;
      line-height: 1.5;
      margin-bottom: 0.25rem;
    }

    span {
      color: $anthrazit;
    }

    h3,
    h4 {
      font-size: 1.25rem;
      color: $black;
      margin-bottom: 0.25rem;
      line-height: 1.2;
    }

    .center-button {
      position: absolute;
      bottom: 1rem;
      left: 0;
      width: 100%;
    }
  }

  div.button {
    margin: 0 auto;
  }
}

// Compare-Cards: Vergleiche nebeneinander
// (im Moment für Direkt/Gruppenbuchungen verwendet)
// TODO: Wird jetzt (Sept.2019) nicht mehr verwendet. Löschen?
/*ul*/
.compare-cards {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
}

/*li*/
.compare-card {
  background: $white;
  color: $body-font-color;
  padding: 1rem 1.5rem;
  margin: 0 0.5rem 1rem;
  flex: 0 0 auto;
  width: calc(100% - 1rem);
  border-radius: 3px;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
  will-change: transform;
  position: relative;
  cursor: pointer;
  
  &::before {
    content:"";
    position: absolute;
    @include coverer;
    box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.10);
    // zweiter hover.schatten hier, aus Performance-Gründen
    // opacity ist schonender zu animieren als box-shadow
    opacity: 0;
    transform: opacity 0.2s;
    will-change: opacity;
    z-index: -1;
  }
  
  
  &:hover {
    transform: scale(1.04);
    transition: transform 0.2s;
    &:before {
      opacity: 1;
      transform: opacity 0.2s;
    }
  }    

  @include breakpoint(medium) {
      margin-left: 1rem;
      margin-right: 1rem;
      width: calc((100% / 2) - 2rem);
  }
  @include breakpoint(large) {
      width: calc((100% / 3) - 2rem);
  }

  > a {
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__icon {
    padding: 1rem;
    text-align: center;
    // evtl. Begleittext unter Icon
    color: $gold;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.2;

    svg,
    img {
      height: rem-calc(60);
    }

    // SVG-Pfade
    svg.svg--neu {
      path {
        fill: #b1986d;
      }
      .svg--neu__schrift {
        fill: #fff;
      }
    }

    svg.svg--group-3 path,   
    svg.svg--group-3 circle,   
    svg.svg--group-5 path,
    svg.svg--group-5 circle {
      fill:none;
      stroke:#b1986d;
      stroke-width:23;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }

    .compare-card:hover & {
      color: $brand;
      // Beim hover: nur die richtigen SVG-Pfade einfärben, 
      // die weiße Schrift musss z.B. weiß bleiben
      svg.svg--neu {
        path {
        fill: $brand;
        }
        .svg--neu__schrift {
          fill: #fff;
        }
      }
      svg.svg--group-3 path,   
      svg.svg--group-3 circle,   
      svg.svg--group-5 path,
      svg.svg--group-5 circle {
        stroke: $brand;
      }
    }
  }


  &__title {
    font-size: 1.5rem;
    font-family: $header-font-family;
    text-align: center;
    margin-bottom: 1rem;
    line-height: 1.1;
    font-weight: 400;
    
    @include breakpoint(medium) {
      font-size: 1.75rem;      
    }
  }
}


// -----------------------------------------------------------------------------
// <ul>-Listen-Styles
// -----------------------------------------------------------------------------

/*ul*/.checkmark-list {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;

  li {
    padding-left: 2em;
    margin-bottom: 0.75rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0.5em;
      top: 0.25em;
      width: 1em;
      height: 1em;
      background-image: url("/assets/gfx/icons/check.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
   
  &--subtle li::before {
    background-image: url("/assets/gfx/icons/check-b.svg");
  }

  &--magenta li::before {
    background-image: url("/assets/gfx/icons/check-magenta.svg");
  }
}