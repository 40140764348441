// FOOTER
// -------------------------------------------
footer {
  padding: 1rem 2rem;
  background-color: $anthrazit;
  color: $white;
  -webkit-transition: padding 0.2s;
  transition: padding 0.2s;
  font-size: 1rem;

  &.sticky-incoming {
    padding-bottom: 70px;
  }

  .column,
  .columns {
    margin-top: 2rem;
  }
  
  a {
    color: silver;
  }

  .button.hollow {
    line-height: 1.2;
    display: block;
    max-width: 230px;
    margin: 1.5rem auto;
    color: $white;
    border-color: $white;
    &:hover {
      color: $primary-color;
      border-color: $primary-color;
    }
  }

  .header {
    font-family: $header-font-family;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 400;
  }

  .linklist {
    margin-bottom: 1rem;
  }

  .social-media.menu {
    color: silver;
    display: inline-block;
    list-style: none;
    margin: 1rem 0;
    li {
      font-size: 2rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
  .partner {
    color: $gold-light;
    // .external {}
  }
  .abi-logo {
    height: 1.75rem;
    display: block;
    margin: 0.5rem auto;
  }
  .powered-by {
    height: 0.875rem;
    display: block;
    margin: 0 auto 1.5rem;
  }
  small {
    font-size: 90%;
  }
}

.ruf-logo {
  background-image: url('/assets/gfx/sprite.png');
  background-position: -405px 0;
  width: 72px;
  height: 80px;
  display: inline-block;
  margin: 0 auto;
}

.footer-phone,
.footer-whatsapp {
  i {
    padding-right: 0.25rem;
  }
  a {
    color: silver;
    font-size: 1.25rem;
  }
}

.footer-bottom {
  margin: 0 auto;
  padding: 2rem 0 1rem;
  text-align: center;

  a {
    font-size: 0.9375rem;
    padding: 0.35rem;
    display: block;
    @include breakpoint(medium) {
      display: inline-block;
      padding: 0 1rem;
      border-right: 1px solid silver;
      &:last-child {
        border-right: none;
      }
    }
  }
}
