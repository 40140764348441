// -----------------------------------------------------------------------------
// Mixins
// -----------------------------------------------------------------------------



/*X-Symbol als CSS*/
@mixin cssX ($background-color: #494949, $width: 30px, $height: 3px) {
  width: $width;
  height: $height;
  background-color: $background-color;
  display: block;
  border-radius: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 0;
    -webkit-transform: translateY(-0.5rem);
    transform: translateY(-0.5rem);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

//Für overlays etc.
@mixin coverer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Lange Wörter umbrechen SRC: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  // -ms-word-break: break-all;
  word-break: break-word;
  // -ms-hyphens: auto;
  // -moz-hyphens: auto;
  // -webkit-hyphens: auto;
  // hyphens: auto;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Fluid-Type
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}