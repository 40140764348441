/* LAZYLOAD - verschiedene Stadien (loading -> loaded)*/
/* -------------------------------------------*/
/* Standard: Opacity + leichter scale-Effekt*/
.lazyload:not([data-bgset]):not(.lazyload--blur):not(.animated) {
  background: #f7f7f7;
  opacity: 0;
  // -webkit-transform: scale(0.95);
  // transform: scale(0.95);
}
.lazyloading:not([data-bgset]):not(.lazyload--blur):not(.animated) {
  background: #f7f7f7 url("/assets/gfx/spin.gif") no-repeat center;
}

.lazyloaded:not([data-bgset]):not(.lazyload--blur):not(.animated) {
  opacity: 1;
  // -webkit-transform: scale(1);
  // transform: scale(1);
  transition: all 0.2s;

  &.slow {
    transition: all 0.6s;
  }
}

// Hintergründe
.lazyload[data-bgset]:not(.lazyload--blur):not(.animated),
.lazyloading[data-bgset]:not(.lazyload--blur):not(.animated) {
  opacity: 0.99;
  // animierter background-gradient-Schimmer (von GoogleDrive gemopst)
  animation: gradient-slide 2.5s ease infinite;
  background: 0 0/300% 300%
    linear-gradient(-61deg, #eeeeee 40%, #f7f7f7 50%, #eeeeee 60%);
}



// Sonderfall Hero-Bild:  evtl mit LQIP/"Blur-up", deshalb ohne Gradient oder Animation
.lazyload.lazyload--blur,
.lazyloading.lazyload--blur {
  opacity: 0.5;
  // blur nur beim Laden 
  filter: blur(0.5rem);
}



@keyframes gradient-slide {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0% 50%;
  }
}



/* Weitere Effekte (als Zusatzklassen) */
.lazyload.slide-in-left {
  transform: translateX(-100%);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.lazyloaded.slide-in-left {
  transform: translateX(0%);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.lazyload.slide-in-bottom {
  transform: translateY(100%);
  transition-delay: 0.2s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.lazyloaded.slide-in-bottom {
  transform: translateX(0%);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.lazyload.bounce-in {
  transform: scale(0.5);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.lazyloaded.bounce-in {
  transform: scale(1);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

/* Padding-Bottom-Trick, schafft Platz für die nohc nicht geladenen Bilder (wirkt flüssiger)*/
// Wird außerdem für info-Karten genutzt, die nur icon+hintergrund und kein Bild haben (in Suchergebnis-Liste), um gleichbleibende Proportionen zu bekommen
// TODO: Klasse wird jetzt wieder verwendet - woanders abspeichern?
.intrinsic {
  position: relative;
  padding-bottom: 65%;
  height: 0;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.ratio-80 {
    padding-bottom: 80%;
  }
}
