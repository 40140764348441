/*
██████  ██    ██  ██████ ██   ██ ██    ██ ███    ██  ██████  ███████ ███████ ████████ ██████  ███████  ██████ ██   ██ ███████
██   ██ ██    ██ ██      ██   ██ ██    ██ ████   ██ ██       ██      ██         ██    ██   ██ ██      ██      ██  ██  ██
██████  ██    ██ ██      ███████ ██    ██ ██ ██  ██ ██   ███ ███████ ███████    ██    ██████  █████   ██      █████   █████
██   ██ ██    ██ ██      ██   ██ ██    ██ ██  ██ ██ ██    ██      ██      ██    ██    ██   ██ ██      ██      ██  ██  ██
██████   ██████   ██████ ██   ██  ██████  ██   ████  ██████  ███████ ███████    ██    ██   ██ ███████  ██████ ██   ██ ███████
*/

// wird bald gegen anuglar-datpicker getauscht
#calculator .datepick-popup {
  margin-bottom: 0;
}

// Buchungsformular ist immer in der Seite, wird aber erst gezeigt, wenn der body der entsprechende Klasse bekommt
// der Rest der Seite wird dann größtenteils ausgeblendet
body.bookingform-open {
  .intro-img,
  header,
  main {
    display: none;
  }

  #bookingFormContainer {
    display: block;
  }
}

// Buchungsformular
#bookingFormContainer {
  background: $white;
  display: none;
  animation: zus-cover-top 0.4s;
}

// Buchungsheader
.booking-header {
  background: $anthrazit;
  position: relative;
  height: 4rem;

  @include breakpoint(medium) {
      height: 4.5rem;
  }

  &__inner {
    max-width: 800px;
    height: 100%;
    margin: 0 auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__logos {
    align-self: flex-start;
    flex: 0 1 auto;
    white-space: nowrap;

    .booking-header-logo--ruf {
      height: 67px;
      width: auto;
      margin-right: 0.25rem;
      @include breakpoint(small only) {
        height: 60px;
      }
    }

    .booking-header-logo--abi {
      height: 25px;
      width: auto;
      @include breakpoint(small only) {
        height: 12px;
      }
    }
  }

  &__hotline {
    flex: 1 1 auto;
    font-size: rem-calc(12);
    color: $white;
    text-align: center;

    @include breakpoint(medium) {
      font-size: rem-calc(16);
    }

    i {
      font-size: 120%;
      margin: 0 0.325em;
    }
    a {
      color: inherit;
    }
  }

  &__right {
    flex: 0 1 auto;
    margin-left: auto;
    line-height: 5rem;
    text-align: right;

    .button {
      color: $medium-gray;
      margin: 0;
      border-color: currentColor;
      font-size: 0.75rem;
      @include breakpoint(small only) {
        font-size: 0.625rem;
        padding: 0.5rem 0.25rem 0.4325rem;
        padding-right: 0.25rem;
        margin-right: -6px;
        letter-spacing: 1px;
      }
      &:hover,
      &:active {
        color: $light-gray;
        border-color: currentColor;
      }
    }
  }
}

.trustbox {
  position: relative;
  display: none;
  float: right;
  margin: 2rem 0 1rem 2rem;
  padding: 1.5rem 1.25rem 0.5rem 1.25rem;
  max-width: 360px;
  font-size: 0.75rem;
  border-radius: 6px;
  background: #eeefef;
  
  @include breakpoint(medium) {
    display: block;
  }

  ul {
    margin: 0 0 0 1rem;
  }

  &__top {
    margin-bottom: 2rem;

    img {
      @include horizontal-center;
      position: absolute;
      top: -2.8rem;
      width: 100%;
      height: 90px;
    }
  }

  &__bottom {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid $black;
    text-align: center;

    img {
      display: inline-block;
      margin: 0.25rem 0.75rem 0.75rem;
      height: 45px;
    }
    // ganz links ein höheres Siegel
    .trustbox-img--large {
      height: 125px;
      float: left;
    }
  }
}

.fehler-code {
  font-family: monospace;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $dark-gray;
}
