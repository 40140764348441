// -----------------------------------------------------------------------------
// *** ADJUST-FOUNDATION ***
// -----------------------------------------------------------------------------
// Hier werden Foundation-Klassen überschrieben und für unser Projekt 
// entprechend angepasst
// -----------------------------------------------------------------------------
// - Foundation-Grid-Modifikatoren 
// - Foundation-Komponenten anpassen 
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
// #GRID-MODIFIKATOREN
// für mehr + weniger Padding zwischen Columns.
// -----------------------------------------------------------------------------

//Sonderklasse für schmaleres Grid = ROW--TIGHT (Inkl. Legacyklasse)

.row.row--tight .column,
.row.tight .column {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
    @include breakpoint(large) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.row .row.row--tight,
.row .row.tight {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem;
    @include breakpoint(large) {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }
}

//Sonderklasse für sehr schmales Grid = ROW--VERY-TIGHT
.row.row--very-tight .column {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
    @include breakpoint(large) {
        padding-left: 0.1875rem;
        padding-right: 0.1875rem;
    }
}

.row .row.row--very-tight {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
    @include breakpoint(large) {
        margin-left: -0.1875rem;
        margin-right: -0.1875rem;
    }
}

//Sonderklasse für weiteres Grid = ROW--SPACED
// nicht mobil geeignet, da ist der negative margin zu groß
.row.row--spaced .column {
    @include breakpoint(medium) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @include breakpoint(large) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.row .row.row--spaced {
    max-width: 100vw;
    @include breakpoint(medium) {
        margin-left: -1rem;
        margin-right: -1rem;
    }
    @include breakpoint(large) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
}




// -----------------------------------------------------------------------------
// #KOMPONENTEN ANPASSEN
// verwendete Foundation-Elemente hier anpassen
// -----------------------------------------------------------------------------

// Buttons
.button,
button {
    font-family: $header-font-family;
    text-transform: uppercase;
    font-weight: 400 !important;
    letter-spacing: 0.075em;
    line-height: 1.2;
    border-radius: 0.625rem;
}

.button.hollow.subtle {
    border-color: $black;
    color: $black;
    &:hover {
        border-color: $brand;
        color: $brand;
    }
}

.button.hollow.on-dark {
    border-color: $white;
    color: $white;
    &:hover {
        border-color: $brand;
        color: $brand;
    }
}

.button.light {
    background: $white;
    color: $black;
    &:hover,
    &:focus {
        background: darken($white, 3%);
        color: $black;
    }
}

/* Shiny - button*/
.shiny {
    position: relative;
    overflow: hidden;

    &:after {
        background: #fff;
        content: "";
        height: 130px;
        left: -75px;
        opacity: 0.25;
        position: absolute;
        top: -55px;
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
        -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 40px;
        z-index: 1;
    }

    &:hover:after {
        left: 150%;
        -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
}


// loading-state
.button.is-loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        top: calc(50% - (1.5em / 2));
        left: calc(50% - (1.5em / 2));
        width: 1.5em;
        height: 1.5em;
        border-radius: 290496px;
        border: 0.125em solid $white;
        border-right-color: transparent;
        border-bottom-color: transparent;
        animation: zus-spin 0.5s infinite linear;
        transform-origin: center;
    }
}


// Foundation-Accordion: besondere Styles für's Buchungsformular
.accordion.accordion--booking {
    background: transparent;
    
    // hartnäckige border zurücksetzen
    :last-child:not(.is-active)>.accordion-title,
    :last-child>.accordion-content:last-child  {
        border: none;
    }
    
    .accordion-content {
        border: none;
        color: $body-font-color;
    }

    .accordion-title {
        // accordion-title so wie die form-subheadings
        background: #f27fbe;
        border: none;
        margin: 0 0 0.5rem;
        font-size: 1.25rem;
        font-family: $header-font-family;
        text-transform: uppercase;
        font-weight: 400;
        color: $white;
        padding-right: 2em;
        word-break: break-word;
        
          &::before {
            position: absolute;
            right: 1rem;
            top: 50%;
            margin-top: 0;
            transform: translateY(-50%);
            font-size: 1em;
            font-family: sans-serif;
        }

        @include breakpoint(large) {
            font-size: 1.5rem;
        }

        &:hover, &:active {
            background: #e63395;
            color: $white;
        }
        // &:focus {
        //     background: #e63395;
        //     color: $white;
        // }
    }
    
    .is-active .accordion-title {
        background: #e63395;
        color: $white;
    }
}

// .always-open: Versicherungen-Tab dadurch immer sichtbar, togglen nicht ermöglichen
#bookingFormContainer .accordion-item.always-open {
    .accordion-title {
        pointer-events: none;
        background: #e63395;
        &:before {
            content: "";
        }
    }
    .accordion-content {
        display: block !important;
        transition: none !important;
    }
}