// SUBNAV - Sab-Navigation und Breadcrumb
// ---------------------------------------------------------


.subnav {
  background-color: $anthrazit;
  min-height: 38px;
  width: 100%;
  @include breakpoint(medium) {
    min-height: 41px;
  }
  .sticky {
    width: 100%;
    // background-color: $anthrazit;
    background-color: transparent;
    /*Bugfix: Sticky wird auf small nicht richtig deaktiviert, hiermit jetzt schon:*/
    @include breakpoint(small only) {
      position: relative !important;
    }
  }
  &.sticky-container {
    @include breakpoint(small only) {
      height: auto !important;
    }
  }
  .is-stuck {
    width: 100%;
    background: $anthrazit;
    box-shadow: 0 3px 3px 0 rgba(17, 17, 17, 0.3);
  }
  .subnav-inner {
    // Wenn .go-back nich ganz links floaten soll, das hier wieder einkommentieren
    // max-width: 75em !important;
    // margin: 0 auto;
  }
  .go-back {
    background-color: $medium-gray;
    float: left;
    margin-left: -0.9375rem;
    text-align: center;
    text-transform: uppercase;
    font-family: $header-font-family;

    &:hover {
      background-color: darken($medium-gray, 10%);
    }
    @include breakpoint(small only) {
        float: none;
    }
    a {
      color: $black;
    }
  }
}
.subnav-list {
  padding: 0;
  background-color: $anthrazit;
  margin: 0;
  color: $white;
  font-size: 1rem;
  list-style-type: none;
  text-align: center;
  text-transform: uppercase;
  font-family: $header-font-family;
  letter-spacing: 0.075em;
  font-weight: 400;
  @include breakpoint(medium) {
    font-size: 1.125rem;
  }
  li {
    display: inline-block;
    margin-right: 0.5rem;
    padding: 0;
    border-radius: 0;
    &:hover,
    &.active {
      color: $white;
      background: $gold;
    }
  }
  .subnav-anfrage {
    /* später evtl anders stylen*/
  }
  a {
    color: inherit;
    display: inline-block;
    padding: 0.5rem;
  }
}

.breadcrumbs {
  margin-top: 0.5rem;
  li:last-of-type a {
    color: $black;
    text-decoration: none;
    cursor: default;
    pointer-events: none;
  }
}
