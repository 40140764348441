
// -----------------------------------------------------------------------------
// *** BASE ***
// -----------------------------------------------------------------------------
// Inhalt:
// - Grundlegende Elemnte
// - Typography
// - Sections
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
// #GRUNDLEGENDES
// -----------------------------------------------------------------------------

body {
    min-width: 320px;
    background-color: $anthrazit;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
}

main {
    background-color: $white;
    margin: 0;
    padding: 0;
    width: auto;
}

// -----------------------------------------------------------------------------
// #TYPOGRAPHY
// -----------------------------------------------------------------------------

h1, .h1 {
    text-transform: uppercase;
    font-family: $header-font-family;
    font-weight: 300;
    line-height: 1.1;
    margin-bottom: 1rem;
    letter-spacing: 0.04em;
    color: $gold-dark; 
    // Wörter standardmäßig umbrechen lassen, weil die h1 so groß ist. Zerstört sonst das Layout (overflow:hidden funktioniert auf mobil manchmal nicht)
    @include word-wrap;
}

h2, .h2 {
    font-weight: 300;
    font-family: $header-font-family;    
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

h3, .h3,
h4, .h4,
h5, .h5 {
    font-weight: 400;
    letter-spacing: 0.03em;
    font-family: $header-font-family;
}


// -----------------------------------------------------------------------------
// #SECTIONS - gut für vertikale Struktur, haben ordentlich Padding
// -----------------------------------------------------------------------------
// Einige Legacy-Styles sind dabei (ohne BEM, "first", "small" etc)
// -----------------------------------------------------------------------------

section,
.section {
    margin: 0;
    padding: 1rem 0;
    position: relative;
    
    @include breakpoint(medium) {
        padding: 2.5rem 0;
    }

    &.section--dark,
    &.dark {
        background: $black;
        color: $white;
        h1 {
            color: $white;
        }
    }

    &.section--large,
    &.spacious {
        padding: 3rem 0;
        @include breakpoint(medium) {
            padding: 5rem 0;
        }
    }

    &.section--small,
    &.small {
        padding: 0.5rem 0;
        @include breakpoint(medium) {
            padding: 1rem 0;
        }
    }
    
    &.section--large-bottom {
        padding-bottom: 3rem;
        @include breakpoint(medium) {
            padding-bottom: 5rem;
        }
    }
    
    &.section--large-top {
        padding-top: 3rem;
        @include breakpoint(medium) {
            padding-top: 5rem;
        }
    }

    &.section--small-bottom {
        padding-bottom: 0.75rem;
        @include breakpoint(medium) {
            padding-bottom: 1.25rem;
        }
    }

    &.section--small-top,
    &.first {
        padding-top: 0.75rem;
        @include breakpoint(medium) {
            padding-top: 1.25rem;
        }
    }

    &.section--no-padding {
        padding: 0;
    }

}