// MASONRY - Byday-, bynight-Elemente
// -----------------------


/* Allgemeine Styles + "Darum Abireisen" */
.masonry-brick {
  margin: 0 0 0.7rem;
  width: 100%;
  background: $white;
  display: inline-block;
  overflow: hidden;
  @include breakpoint(medium) {
    margin: 0 0.35rem 0.7rem;
    width: 48%; /* Fallback */
    width: calc(50% - 0.7rem);
  }
  @include breakpoint(large) {
    width: 31%; /* Fallback */
    width: calc(33.3333% - 0.7rem);
  }
  .large-two & {
    // Zusatzklasse .large-two auf parent-Container: Nur zweispaltig, auch auf Dektop
    @include breakpoint(large) {
      margin: 0 0.35rem 0.7rem;
      width: 48%; /* Fallback */
      width: calc(50% - 0.7rem);
    }
  }

  img {
    width: 100%;
  }

  &.dark-bg {
    background-color: $dark-bg;
    color: white;
  }

  &.signal {
    background-color: $signal;
    .color {
      color: $brown;
    }
  }

  ul {
    margin: 0.5rem 0.5rem 1rem 1rem;
  }
}

.masonry-img {
  position: relative;

  // feste Höhe bis bilder geladen
  img.lazyload,
  img.lazyloading {
      height: 300px;
  }
}

.masonry-desc {
  padding: 1.5rem;
  position: relative;

  h3 {
    text-align: center;
    line-height: 1.2;
    font-family: $header-font-family;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
  
    span {
      font-size: 1.5rem;
      display: inline-block;
      line-height: 1.2;
      text-transform: none;
      letter-spacing: normal;
    }

    small {
      display: block;
      font-size: 80%;
      line-height: 1.2;
      margin-top: 0.25em;
    }
  }
  p {
    margin: 0;
  }
}

// Byday + bynight spezifisch
.byday, .bynight {
  .masonry-desc {
    margin-top: 3px;
    padding-top: 1.25rem;
    padding-bottom: 1.75rem;
  }

  .masonry-brick:nth-of-type(even) .masonry-desc {
    background-color: $signal;
    color: $black;
    h3 {
      color: $black;
      span {
        color: $brown;
      }
    }
  }
}
.byday .masonry-desc {
  background: $white;
  color: $black;
  h3 {
    color: $gold-dark;
    span {
      color: $gold-dark;
    }
  }
}

.bynight .masonry-desc {
  background: $dark-bg;
  color: $white;
  h3 {
    color: $white;
    span {
      color: $gold;
    }
  }
}

// Geheimtips + Jointheclub.. Produktseiten im Masonry-Format
.desti-bricks {
  h3 {
    font-family: $header-font-family, sans-serif;
    color: $gold-dark;
  }
  small {
      color: currentColor;
      font-size: 65%;
  }

  .masonry-brick {
    background: $black;
    color: $white;
    display: inline-block;
    overflow: hidden;
    &:nth-of-type(2n+1) {
      background-color: $light-bg;
      color: $black;
      h3 {
        color: $brown;
      }
    }

  }
  .masonry-img {
    .button {
      @include absolute-center;
      background-color: transparentize($anthrazit, 0.5);
      border: 2px solid $white;
      color: $white;
      font-weight: $white;
      line-height: 1.3;
      font-size: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-width: 200px;
      transition: background 0.2s;
      @include breakpoint(medium) {
        font-size: 1.5rem;
        min-width: 250px;
      }
      &:hover {
        background-color: transparentize($anthrazit, 0.2);
        transition: background 0.2s;
      }
    }
  }
}
